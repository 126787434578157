import { cn } from '@bem-react/classname'
import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import qs from 'qs'
import { FC, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { IDailyHistory } from '@/core/services/App/AppModel'
import {
  balancesSelector,
  dailyHistorySelector,
} from '@/core/services/App/store/AppSelector'
import {
  getAccountDailyHistoryThunk,
  getBalancesThunk,
} from '@/core/services/App/store/AppSlice'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Card } from '@/components/UI/Card'
import { DataGrid } from '@/components/UI/DataGrid'
import { Status } from '@/components/UI/Status'
import { Title } from '@/components/UI/Title'

import { userSelector } from '../Auth/store/AuthSelect'
import { ICustomerResponse } from '../Clients/models/CardModel'
import { customersSelector } from '../Clients/store/ClientsSelector'
import { getClientsThunk } from '../Clients/store/ClientsSlice'
import { invoicesSelector } from '../Invoices/store/InvoiceSelector'
import { getInvoicesThunk } from '../Invoices/store/InvoiceSlice'
import './styles/Dashboard.scss'

const cnDashboard = cn('Dashboard')

export const Dashboard: FC = () => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()

  const balances = useAppSelector(balancesSelector)
  const customers = useAppSelector(customersSelector)
  const user = useAppSelector(userSelector)
  const invoices = useAppSelector(invoicesSelector)
  const dailyHistory = useAppSelector(dailyHistorySelector)

  const [daily, setDaily] = useState<IDailyHistory | null>(null)

  useEffect(() => {
    if (dailyHistory && dailyHistory.items && dailyHistory.items.length) {
      const start = new Date()
      start.setUTCHours(0, 0, 0, 0)
      const current = dailyHistory.items.find(
        el =>
          dayjs(el.Day).format('YYYY-MM-DDTHH:mm:ss') ===
          dayjs(start).format('YYYY-MM-DDTHH:mm:ss'),
      )

      console.log(current)

      if (current) setDaily(current)
    }
  }, [dailyHistory])

  const columns = useMemo<ColumnDef<ICustomerResponse>[]>(
    () => [
      {
        id: 'id_doc',
        header: 'Doc ID',
        accessorKey: 'id_doc',
        enableSorting: false,
        // maxSize: 150,
      },

      {
        id: 'first_name',
        header: 'First Name',
        accessorKey: 'first_name',
        enableSorting: false,
        // maxSize: 150,
      },

      {
        id: 'last_name',
        header: 'Last Name',
        accessorKey: 'last_name',
        enableSorting: false,
        // maxSize: 150,
      },

      {
        id: 'phone',
        header: 'Phone',
        accessorKey: 'phone',
        enableSorting: false,
        // maxSize: 150,
      },

      {
        id: 'dob',
        header: 'Birthday',
        accessorKey: 'dob',
        cell: ({ row }) => dayjs(row.original.dob).format('DD.MM.YYYY'),
        enableSorting: false,
        // maxSize: 150,
      },

      {
        id: 'created_at',
        header: 'Created At',
        accessorKey: 'created_at',
        cell: ({ row }) =>
          dayjs(row.original.created_at).format('DD.MM.YYYY HH:mm'),
        enableSorting: false,
        // maxSize: 150,
      },
    ],
    [],
  )

  useEffect(() => {
    getData()
    getInvoices()
    getClients()
  }, [])

  const getData = async () => {
    const query = qs.stringify({
      page: 1,
      limit: 10,
    })

    try {
      await dispatch(getBalancesThunk()).unwrap()
      await dispatch(getAccountDailyHistoryThunk(query)).unwrap()
    } catch (error) {
      console.log('getBalancesThunk', error)
    }
  }

  const getClients = async () => {
    const query = qs.stringify({
      page: 1,
      limit: 5,
    })
    try {
      await dispatch(getClientsThunk(query)).unwrap()
    } catch (error) {
      console.log('getClients', error)
    }
  }

  const getInvoices = async () => {
    if (!user) return

    const query = qs.stringify({
      populate: ['payment_status'],
      filters: {
        users_permissions_user: {
          id: {
            $eq: user.id,
          },
        },
        payment_status: {
          name: {
            $eq: 'PAID',
          },
        },
      },
      sort: ['id:desc'],
    })

    try {
      await dispatch(getInvoicesThunk(query)).unwrap()
    } catch (error) {
      console.log('getInvoicesThunk', error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columnsInvoice = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'ID',
        header: 'id',
        accessorKey: 'id',
        enableSorting: false,
        maxSize: 80,
      },
      {
        id: 'invoiceDate',
        header: 'Create date',
        // accessorKey: 'attributes.invoiceDate',
        cell: ({ row }) =>
          dayjs(row.original.attributes.invoiceDate).format('DD.MM.YYYY'),
        enableSorting: false,
        maxSize: 150,
      },
      {
        id: 'dueDate',
        header: 'Due Date',
        // accessorKey: 'attributes.dueDate',
        cell: ({ row }) =>
          dayjs(row.original.attributes.dueDate).format('DD.MM.YYYY'),
        enableSorting: false,
        maxSize: 150,
      },
      {
        id: 'balance',
        header: 'Liquidity Balance',
        // accessorKey: 'attributes.replenishmentLiquidityBalance',
        cell: ({ row }) => (
          <>
            <span className={'icon-USD'}></span>
            {row.original.attributes.replenishmentLiquidityBalance | 0}
          </>
        ),
        enableSorting: false,
        maxSize: 150,
      },
      {
        id: 'serviceBalance',
        header: 'Service Balance',
        // accessorKey: 'attributes.replenishmentServiceBalance',
        cell: ({ row }) => (
          <>
            <span className={'icon-USD'}></span>
            {row.original.attributes.replenishmentServiceBalance | 0}
          </>
        ),
        enableSorting: false,
        maxSize: 150,
      },

      {
        id: 'monthlyServiceFee',
        header: 'Monthly Fee',
        // accessorKey: 'attributes.monthlyServiceFee',
        cell: ({ row }) => (
          <>
            <span className={'icon-USD'}></span>
            {row.original.attributes.monthlyServiceFee | 0}
          </>
        ),
        enableSorting: false,
        maxSize: 150,
      },
      {
        id: 'payment_status',
        header: 'Payment Status',
        // accessorKey: 'attributes.payment_status.data.attributes.title',

        cell: ({ row }) => (
          <Status
            type={row.original.attributes.payment_status.data.attributes.name}
          >
            {row.original.attributes.payment_status.data.attributes.name}
          </Status>
        ),
        enableSorting: false,
        maxSize: 150,
      },
    ],
    [],
  )

  return (
    <>
      <Helmet>
        <title>{t('dashboard-title')}</title>
        <meta name='description' content={t('dashboard-description')} />
      </Helmet>
      <div className={cnDashboard()}>
        <Card className={cnDashboard('card', { padding: true })}>
          <Title size='md' className={cnDashboard('title')}>
            {t('Statistics today')} {dayjs().format('DD.MM.YYYY')}
          </Title>

          <div className={cnDashboard('values')}>
            <Card color='light' className={cnDashboard('total')}>
              <div className={cnDashboard('value')}>{daily?.CardCnt || 0}</div>
              <div className={cnDashboard('key')}>Сards issued</div>
            </Card>
            <Card color='light' className={cnDashboard('total')}>
              <div className={cnDashboard('value')}>{daily?.KycCnt || 0}</div>
              <div className={cnDashboard('key')}> Passed KYC</div>
            </Card>
            <Card color='light' className={cnDashboard('total')}>
              <div className={cnDashboard('value')}>
                {daily?.DepositsToCardsCnt || 0}
              </div>
              <div className={cnDashboard('key')}>
                Number of top-ups on cards
              </div>
            </Card>
            <Card color='light' className={cnDashboard('total')}>
              <div className={cnDashboard('value')}>
                <span className={'icon-USD'}></span>
                {daily?.CardAmount.toFixed(2) || 0}
              </div>
              <div className={cnDashboard('key')}>Deducted card issue fee</div>
            </Card>
            <Card color='light' className={cnDashboard('total')}>
              <div className={cnDashboard('value')}>
                <span className={'icon-USD'}></span>
                {daily?.KycAmount.toFixed(2) || 0}
              </div>
              <div className={cnDashboard('key')}>
                Deducted fee for KYC checks
              </div>
            </Card>

            <Card color='light' className={cnDashboard('total')}>
              <div className={cnDashboard('value')}>
                <span className={'icon-USD'}></span>
                {daily?.DepositsToCardsAmount.toFixed(2) || 0}
              </div>
              <div className={cnDashboard('key')}>
                Amount of deposits on cards
              </div>
            </Card>
          </div>
        </Card>

        <Card className={cnDashboard('card', { flex: true })}>
          <div className={cnDashboard('total', { empty: true })}>
            <div className={cnDashboard('value')}>
              <span className={'icon-USD'}></span>
              {balances ? balances.balance.toFixed(2) : 0}
            </div>
            <div className={cnDashboard('key')}>{t('Liquidity balance')}</div>
          </div>

          <Link
            to={'/c/history/drop-balance-to-card'}
            className={cnDashboard('link')}
          >
            {t('See history')}
          </Link>
        </Card>
        <Card className={cnDashboard('card', { flex: true })}>
          <div className={cnDashboard('total', { empty: true })}>
            <div className={cnDashboard('value')}>
              <span className={'icon-USD'}></span>
              {balances ? balances.service_balance.toFixed(2) : 0}
            </div>
            <div className={cnDashboard('key')}>{t('Service balance')}</div>
          </div>

          <Link to={'/c/history/fee'} className={cnDashboard('link')}>
            {t('See history')}
          </Link>
        </Card>

        {customers && (
          <Card className={cnDashboard('card')}>
            <Title size='md' className={cnDashboard('title', { top: true })}>
              {t('Top customers')}
            </Title>

            <DataGrid data={customers?.items} columns={columns} />
          </Card>
        )}

        <Card className={cnDashboard('card')}>
          <Title size='md' className={cnDashboard('title', { top: true })}>
            {t('Last paid invoice')}
          </Title>

          {invoices && (
            <DataGrid data={invoices.data} columns={columnsInvoice} />
          )}
        </Card>
      </div>
    </>
  )
}
