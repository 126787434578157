import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '../Icon'
import './NoData.scss'

const cnNoData = cn('NoData')

interface INoData
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title?: string
  text?: string
  icon?: string
}

export const NoData: FC<INoData> = ({ className, title, text, icon }) => {
  const { t } = useTranslation('translation')
  return (
    <div className={cnNoData(null, [className])}>
      <div className={cnNoData('icon')}>
        <Icon name={icon} />
      </div>
      <div className={cnNoData('title')}>{title ? title : t('Not found')}</div>
      <div className={cnNoData('text')}>{text}</div>
    </div>
  )
}
