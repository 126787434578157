import { cn } from '@bem-react/classname'
import { AnimatePresence, motion } from 'framer-motion'
import { FC } from 'react'
import { useAlert } from 'react-alert'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { adminSelector } from '@/core/services/Admin/store/AppSelector'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Button } from '@/components/UI/Button'
import { Icon } from '@/components/UI/Icon'

import { userSelector } from '@/modules/Auth/store/AuthSelect'

import { IInvoice } from '../../models/InvoiceModel'
import { updateInvoiceThunk } from '../../store/InvoiceSlice'
import './ViewInvoice.scss'

const cnViewInvoice = cn('ViewInvoice')

interface IViewInvoice {
  close: () => void
  onSuccess: () => void
  invoice: IInvoice
  open?: boolean
}

export const ViewInvoice: FC<IViewInvoice> = ({
  close,
  onSuccess,
  invoice,
  open,
}) => {
  const { t } = useTranslation('translation')
  const alert = useAlert()
  const dispatch = useAppDispatch()

  const user = useAppSelector(userSelector)
  const isAdmin = useAppSelector(adminSelector)

  const paid = async () => {
    try {
      await dispatch(
        updateInvoiceThunk({ payment_status: { id: 1 }, id: invoice.id }),
      )
      onSuccess()
      close()
    } catch (error) {
      console.log('paid', error)
    }
  }

  return (
    <AnimatePresence>
      {open && (
        <div className={cnViewInvoice()}>
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={cnViewInvoice('overlay')}
            onClick={() => close()}
          ></motion.div>
          <motion.div
            initial={{ x: '100%' }}
            exit={{ x: '100%' }}
            animate={{ x: 0 }}
            className={cnViewInvoice('wrap')}
          >
            <div className={cnViewInvoice('top')}>
              <div className={cnViewInvoice('title')}>Invoice Details</div>
              <button type='button' onClick={close}>
                <Icon name='close' />
              </button>
            </div>

            <div className={cnViewInvoice('details')}>
              <div className={cnViewInvoice('item')}>
                <div className={cnViewInvoice('key')}>ID</div>
                <div className={cnViewInvoice('value')}>{invoice.id}</div>
              </div>

              <div className={cnViewInvoice('item')}>
                <div className={cnViewInvoice('key')}>Created At</div>
                <div className={cnViewInvoice('value')}>
                  {invoice.attributes.invoiceDate}
                </div>
              </div>

              <div className={cnViewInvoice('item')}>
                <div className={cnViewInvoice('key')}>Expired At</div>
                <div className={cnViewInvoice('value')}>
                  {invoice.attributes.dueDate}
                </div>
              </div>

              {invoice.attributes.replenishmentLiquidityBalance && (
                <div className={cnViewInvoice('item')}>
                  <div className={cnViewInvoice('key')}>Liquidity Balance</div>
                  <div className={cnViewInvoice('value')}>
                    <span className={'icon-USD'}></span>
                    {invoice.attributes.replenishmentLiquidityBalance | 0}
                  </div>
                </div>
              )}

              {invoice.attributes.replenishmentServiceBalance && (
                <div className={cnViewInvoice('item')}>
                  <div className={cnViewInvoice('key')}>Service Balance</div>
                  <div className={cnViewInvoice('value')}>
                    <span className={'icon-USD'}></span>
                    {invoice.attributes.replenishmentServiceBalance | 0}
                  </div>
                </div>
              )}

              <div className={cnViewInvoice('item')}>
                <div className={cnViewInvoice('key')}>Monthly Service Fee</div>
                <div className={cnViewInvoice('value')}>
                  <span className={'icon-USD'}></span>
                  {invoice.attributes &&
                    invoice.attributes.monthlyServiceFee &&
                    invoice.attributes.monthlyServiceFee | 0}
                </div>
              </div>

              <div className={cnViewInvoice('item')}>
                <div className={cnViewInvoice('key')}>Status</div>
                <div className={cnViewInvoice('value')}>
                  {invoice.attributes.payment_status.data.attributes.title}
                </div>
              </div>

              {isAdmin && (
                <div className={cnViewInvoice('item')}>
                  <div className={cnViewInvoice('key')}>Company</div>
                  <div className={cnViewInvoice('value')}>
                    {
                      invoice.attributes.users_permissions_user.data.attributes
                        .username
                    }
                  </div>
                </div>
              )}

              {user && user.wallet && !isAdmin && (
                <div className={cnViewInvoice('item')}>
                  <div className={cnViewInvoice('key')}>Wallet</div>
                  <CopyToClipboard
                    text={user.wallet.wallet}
                    onCopy={() => alert.success(t('Copied!'))}
                  >
                    <button className={cnViewInvoice('value', { copy: true })}>
                      {user.wallet.wallet} ({user.wallet.typeWallet})
                      <Icon name='copy' stroke='#1a82ff' />
                    </button>
                  </CopyToClipboard>
                </div>
              )}
            </div>

            {isAdmin && (
              <Button className={cnViewInvoice('paid')} onClick={paid}>
                {t('Paid')}
              </Button>
            )}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}
