import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store/store'

const theme = (state: RootState) => state.appReducer
export const themeSelector = createSelector(
  theme,
  appReducer => appReducer.theme,
)

const balances = (state: RootState) => state.appReducer
export const balancesSelector = createSelector(
  balances,
  appReducer => appReducer.balances,
)

const history = (state: RootState) => state.appReducer
export const historySelector = createSelector(
  history,
  appReducer => appReducer.history,
)

const dailyHistory = (state: RootState) => state.appReducer
export const dailyHistorySelector = createSelector(
  dailyHistory,
  appReducer => appReducer.dailyHistory,
)
