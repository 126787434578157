import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import AuthService from '../AuthService'
import {
  AuthModel,
  ChangePasswordModel,
  IUser,
  RegisterModel,
  UserModel,
} from '../models/AuthModel'

interface AuthState {
  user: IUser | null
  users: IUser[]
  isAuth: boolean
  isAdmin: boolean
}

const initialState: AuthState = {
  user: null,
  users: [],
  isAuth: false,
  isAdmin: false,
}

export const loginThunk = createAsyncThunk(
  'auth/loginThunk',
  async (data: AuthModel, { rejectWithValue }) => {
    try {
      return await AuthService.login(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getUserThunk = createAsyncThunk(
  'auth/getUserThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await AuthService.getUser()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const updateUserThunk = createAsyncThunk(
  'auth/updateUserThunk',
  async (data: UserModel, { rejectWithValue }) => {
    try {
      return await AuthService.updateUser(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const changePasswordThunk = createAsyncThunk(
  'auth/changePasswordThunk',
  async (data: ChangePasswordModel, { rejectWithValue }) => {
    try {
      return await AuthService.changePassword(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getUsersThunk = createAsyncThunk(
  'auth/getUsersThunk',
  async (query: string, { rejectWithValue }) => {
    try {
      return await AuthService.getUsers(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const addUserThunk = createAsyncThunk(
  'auth/addUserThunk',
  async (data: RegisterModel, { rejectWithValue }) => {
    try {
      return await AuthService.addUser(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder

      .addCase(getUsersThunk.fulfilled, (state, action) => {
        state.users = action.payload
      })
      .addCase(getUsersThunk.rejected, state => {
        state.users = []
      })

      .addCase(loginThunk.fulfilled, (state, action) => {
        state.user = action.payload.user
        state.isAuth = true
        state.isAdmin = action.payload.user.isAdmin
      })
      .addCase(loginThunk.rejected, state => {
        state.user = null
        state.isAuth = false
        state.isAdmin = false
      })

      .addCase(getUserThunk.fulfilled, (state, action) => {
        state.user = action.payload
        state.isAuth = true
        state.isAdmin = action.payload.isAdmin
      })
      .addCase(getUserThunk.rejected, state => {
        state.user = null
        state.isAuth = false
        state.isAdmin = false
      })

      .addCase(changePasswordThunk.fulfilled, (state, action) => {
        state.user = action.payload.user
      })
      .addCase(changePasswordThunk.rejected, state => {
        state.user = null
      })
  },
})

export const { actions, reducer } = authSlice
