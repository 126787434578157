import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store/store'

const fee = (state: RootState) => state.adminReducer
export const feeSelector = createSelector(fee, adminReducer => adminReducer.fee)

const isAdmin = (state: RootState) => state.adminReducer
export const adminSelector = createSelector(
  isAdmin,
  adminReducer => adminReducer.isAdmin,
)

const userAdmin = (state: RootState) => state.adminReducer
export const userAdminSelector = createSelector(
  userAdmin,
  adminReducer => adminReducer.userAdmin,
)

const merchants = (state: RootState) => state.adminReducer
export const merchantsSelector = createSelector(
  merchants,
  adminReducer => adminReducer.merchants,
)

const invoices = (state: RootState) => state.adminReducer
export const invoicesAdminSelector = createSelector(
  invoices,
  adminReducer => adminReducer.invoices,
)
