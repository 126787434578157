import { cn } from '@bem-react/classname'
import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import qs from 'qs'
import { FC, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { adminSelector } from '@/core/services/Admin/store/AppSelector'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Button } from '@/components/UI/Button'
import { Card } from '@/components/UI/Card'
import { DataGrid } from '@/components/UI/DataGrid'
import { Icon } from '@/components/UI/Icon'
import { NoData } from '@/components/UI/NoData'
import { Status } from '@/components/UI/Status'
import { Title } from '@/components/UI/Title'

import { userSelector } from '../Auth/store/AuthSelect'
import { getUsersThunk } from '../Auth/store/AuthSlice'
import './Invoices.scss'
import { CreateInvoice } from './components/CreateInvoice'
import { ViewInvoice } from './components/ViewInvoce'
import { IInvoice } from './models/InvoiceModel'
import { invoicesSelector } from './store/InvoiceSelector'
import { getInvoicesThunk } from './store/InvoiceSlice'

const cnInvoices = cn('Invoices')

export const Invoices: FC = () => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()

  const user = useAppSelector(userSelector)
  const invoices = useAppSelector(invoicesSelector)
  const isAdmin = useAppSelector(adminSelector)

  const [isCreateInvoice, setCreateInvoice] = useState<boolean>(false)
  const [isViewInvoice, setViewInvoice] = useState<boolean>(false)
  const [invoice, setInvoice] = useState<IInvoice | null>(null)

  useEffect(() => {
    if (isAdmin) getUsers()
  }, [isAdmin])

  useEffect(() => {
    getInvoices()
  }, [])

  const getUsers = async () => {
    const query = qs.stringify({
      populate: '*',
      filters: {
        isAdmin: {
          $eq: false,
        },
      },
    })

    try {
      await dispatch(getUsersThunk(query)).unwrap()
    } catch (error) {
      console.log('getBalancesThunk', error)
    }
  }

  const getInvoices = async () => {
    if (!user) return

    const query = qs.stringify({
      populate: {
        payment_status: true,
        users_permissions_user: isAdmin,
      },
      filters: {
        users_permissions_user: {
          id: {
            $eq: isAdmin ? undefined : user.id,
          },
        },
      },
      sort: ['id:desc'],
    })

    try {
      await dispatch(getInvoicesThunk(query)).unwrap()
    } catch (error) {
      console.log('getInvoicesThunk', error)
    }
  }

  const onSuccess = async () => {
    setCreateInvoice(false)
    await getInvoices()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'ID',
        header: 'id',
        accessorKey: 'id',
        enableSorting: false,
        maxSize: 150,
      },
      {
        id: 'invoiceDate',
        header: 'Create date',
        cell: ({ row }) =>
          dayjs(row.original.attributes.invoiceDate).format('DD.MM.YYYY'),
        // accessorKey: 'attributes.invoiceDate',
        enableSorting: false,
        maxSize: 150,
      },
      {
        id: 'dueDate',
        header: 'Due Date',
        accessorKey: 'attributes.dueDate',
        cell: ({ row }) =>
          dayjs(row.original.attributes.dueDate).format('DD.MM.YYYY'),
        enableSorting: false,
        maxSize: 150,
      },

      {
        id: 'payment_status',
        header: 'Payment Status',
        // accessorKey: 'attributes.payment_status.data.attributes.title',

        cell: ({ row }) => (
          <Status
            type={row.original.attributes.payment_status.data.attributes.name}
          >
            {row.original.attributes.payment_status.data.attributes.name}
          </Status>
        ),
        enableSorting: false,
        maxSize: 150,
      },

      {
        id: 'actions',
        header: () => null,
        cell: ({ row }) => (
          <Button
            color='gray'
            onClick={() => {
              setInvoice(row.original)
              setViewInvoice(true)
            }}
          >
            <Icon name='eye' width={20} height={20} stroke='#2B2B2B' />
          </Button>
        ),
        enableSorting: false,
        maxSize: 40,
      },
    ],
    [],
  )

  return (
    <>
      <Helmet>
        <title>{t('invoices-title')}</title>
        <meta name='description' content={t('invoices-description')} />
      </Helmet>
      <div className={cnInvoices()}>
        <Card className={cnInvoices('card')}>
          <div className={cnInvoices('header')}>
            <Title size='md' className={cnInvoices('title')}>
              {t('Invoices')}
            </Title>
            <div className={cnInvoices('actions')}>
              <Button
                iconPosition='left'
                icon={<Icon name='plus' fill='white' />}
                onClick={() => setCreateInvoice(true)}
              >
                {t('Create invoice')}
              </Button>
              {/* <Button
                iconPosition='left'
                icon={<Icon name='filter' />}
                color='gray'
              >
                {t('Filter')}
              </Button> */}
            </div>
          </div>
          {invoices ? (
            <DataGrid data={invoices.data} columns={columns} />
          ) : (
            <NoData
              icon='invoice'
              text={t('There have not been any invoices yet')}
            />
          )}
        </Card>
      </div>
      <CreateInvoice
        close={() => setCreateInvoice(false)}
        onSuccess={onSuccess}
        open={isCreateInvoice}
      />

      {invoice && (
        <ViewInvoice
          invoice={invoice}
          close={() => setViewInvoice(false)}
          onSuccess={() => getInvoices()}
          open={isViewInvoice}
        />
      )}
    </>
  )
}
