import { FC, useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { Private } from '@/components/Layout/Private'
import { Loading } from '@/components/UI/Loading'

import { authSelector, userSelector } from '@/modules/Auth/store/AuthSelect'
import { getUserThunk } from '@/modules/Auth/store/AuthSlice'

import {
  adminSelector,
  userAdminSelector,
} from './services/Admin/store/AppSelector'
import { getUserAdminThunk } from './services/Admin/store/AppSlice'
import { useAppDispatch, useAppSelector } from './store/hooks'

export const PrivateRoute: FC = () => {
  const dispatch = useAppDispatch()

  const isAuth = useAppSelector(authSelector)
  const isAdmin = useAppSelector(adminSelector)
  const user = useAppSelector(userSelector)
  const userAdmin = useAppSelector(userAdminSelector)

  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const token = localStorage.getItem('token')
    const admin = localStorage.getItem('admin')

    if (token && !user && !admin) getUser()
    else if (token && !userAdmin && admin) getAdmin()
    else setLoading(false)
  }, [])

  const getAdmin = async () => {
    try {
      await dispatch(getUserAdminThunk()).unwrap()

      setLoading(false)
    } catch (error) {
      console.log('getAdmin error', error)
      setLoading(false)
    }
  }

  const getUser = async () => {
    try {
      await dispatch(getUserThunk()).unwrap()
      setLoading(false)
    } catch (error) {
      console.log('getUser error', error)
      setLoading(false)
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return isAuth || isAdmin ? (
    <Private>
      <Outlet />
    </Private>
  ) : (
    <Navigate to={'/'} />
  )
}
