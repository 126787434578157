import { IUser } from '@/modules/Auth/models/AuthModel'

export interface IFee {
  'card-issue': number
  kyc: number
}

export interface IRequestFee {
  name: string
  amount: number
}

export interface IRequestBalance {
  email: string
  amount: number
}

export class AdminLoginModel {
  email = ''
  password = ''
}

export interface IUserAdminRole {
  id: number
  name: string
  description: string
  code: string
}
export interface IUserAdmin {
  data: {
    id: number
    firstname: string
    lastname: string
    username: string
    email: string
    isActive: boolean
    blocked: boolean
    preferedLanguage: string
    createdAt: Date
    updatedAt: Date
    roles: IUserAdminRole
  }
}

export interface IAdminLoginResponse {
  data: {
    token: string
    user: {
      id: number
      firstname: string
      lastname: string
      username: string
      email: string
      isActive: boolean
      blocked: boolean
      preferedLanguage: string
      createdAt: Date
      updatedAt: Date
    }
  }
}

export interface IDefaultAdminPaginationResponse {
  page: number
  pageSize: number
  pageCount: number
  total: number
}
export interface IDefaultAdminResponse<T> {
  results: T
  pagination: IDefaultAdminPaginationResponse
}

export interface ICreatedByOrUpdatedBy {
  id: number
  firstname: string
  lastname: string
  username: string
}
export interface IMerchantResponse extends IUser {
  role: IUserAdminRole
  createdBy: ICreatedByOrUpdatedBy
  updatedBy: ICreatedByOrUpdatedBy
}

export interface IInvoiceResponse {
  id: number
  monthlyServiceFee: number | null
  replenishmentLiquidityBalance: number | null
  replenishmentServiceBalance: number | null
  invoiceDate: Date
  dueDate: Date
  createdAt: Date
  updatedAt: Date
  publishedAt: Date
  editable: boolean
  users_permissions_user: {
    id: number
    username: string
    email: string
    confirmed: false
    blocked: false
    createdAt: Date
    updatedAt: Date
    token: string
    phone: string
    country: null
    registrationNumber: string | null
    balance: number
    serviceBalance: number
    isFirst: boolean
  }
  payment_status: {
    id: number
    name: string
    createdAt: Date
    updatedAt: Date
    publishedAt: Date
    title: string
  }
  createdBy: string | null
  updatedBy: string | null
}

export interface CreateInvoiceModelConnectObj {
  id?: number
  name?: string
  position: { end: boolean }
}
export class CreateInvoiceModelConnect {
  connect: CreateInvoiceModelConnectObj[] = []
  disconnect: CreateInvoiceModelConnectObj[] = []
}
export class CreateInvoiceModel {
  dueDate?: Date | string = ''
  editable?: boolean = false
  invoiceDate?: Date | string = ''
  monthlyServiceFee?: number | null = null
  payment_status?: CreateInvoiceModelConnect = new CreateInvoiceModelConnect()
  replenishmentLiquidityBalance: number | null = null
  replenishmentServiceBalance: number | null = null
  users_permissions_user?: CreateInvoiceModelConnect =
    new CreateInvoiceModelConnect()

  userId?: number | null = null
}
