/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios'

import { http } from '@/utils/http'

import { IInvoice, InvoiceModel } from './models/InvoiceModel'

class InvoiceService {
  async createInvoice(data: InvoiceModel): Promise<any> {
    const res = await http.post<InvoiceModel, AxiosResponse<any>>(
      `/invoices`,
      data,
    )

    return res.data
  }

  async getInvoices(query: string): Promise<any> {
    const res = await http.get<string, AxiosResponse<any>>(
      `/invoices${query ? '?' + query : ''}`,
    )

    return res.data
  }

  async updateInvoice(invoice: any): Promise<IInvoice> {
    const { id, ...data } = invoice
    const res = await http.put<any, any>(`/invoices/${id}`, {
      data,
    })

    return res.data
  }
}

export default new InvoiceService()
