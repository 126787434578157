import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import './Status.scss'

const cnStatus = cn('Status')

export type StatusType =
  | 'ERROR'
  | 'PAID'
  | 'COMPLETE'
  | 'UNPAID'
  | 'AWAITING_PAYMENT'
  | 'ACTIVE'

interface IStatus
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  type?: StatusType
}

export const Status: FC<IStatus> = ({
  children,
  className,
  type = 'ERROR',
}) => {
  return (
    <div
      className={cnStatus(
        {
          error: type === 'ERROR',
          success: type === 'COMPLETE' || type === 'PAID' || type === 'ACTIVE',
          warning: type === 'UNPAID',
          info: type === 'AWAITING_PAYMENT',
        },
        [className],
      )}
    >
      {children}
    </div>
  )
}
