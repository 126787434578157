import { AxiosResponse } from 'axios'

import { IDefaultResponse } from '@/core/interfaces'

import { http } from '@/utils/http'

import { ICustomerResponse } from './models/CardModel'

class ClientsService {
  async getClients(
    query: string,
  ): Promise<IDefaultResponse<ICustomerResponse[]>> {
    const res = await http.get<
      null,
      AxiosResponse<IDefaultResponse<ICustomerResponse[]>>
    >(`/customers${query ? '?' + query : ''}`)

    return res.data
  }
}

export default new ClientsService()
