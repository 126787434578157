import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IDefaultResponse } from '@/core/interfaces'

import CardsService from '../CardsService'
import { ICardResponse } from '../models/CardModel'

interface CardsState {
  cards: IDefaultResponse<ICardResponse[]> | null
}

const initialState: CardsState = {
  cards: null,
}

export const getCardsThunk = createAsyncThunk(
  'cards/getCardsThunk',
  async (query: string, { rejectWithValue }) => {
    try {
      return await CardsService.getCards(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder

      .addCase(getCardsThunk.fulfilled, (state, action) => {
        state.cards = action.payload
      })
      .addCase(getCardsThunk.rejected, state => {
        state.cards = null
      })
  },
})

export const { actions, reducer } = cardsSlice
