import { FC, ReactNode, createContext, useEffect, useState } from 'react'

const StorageKey = 'color-theme'

const supportedThemes = {
  light: 'light',
  dark: 'dark',
}

export type Themes = keyof typeof supportedThemes

export const ThemeContext = createContext<
  | {
      theme: Themes
      setTheme: (theme: Themes) => void
      supportedThemes: { [key: string]: string }
    }
  | undefined
>(undefined)

const getTheme = (): Themes => {
  let theme = localStorage.getItem(StorageKey)

  if (!theme) {
    localStorage.setItem(StorageKey, 'light')
    theme = 'light'
  }

  return theme as Themes
}

interface IThemeProvider {
  children: ReactNode
}

export const ThemeProvider: FC<IThemeProvider> = ({ children }) => {
  const [theme, setTheme] = useState<Themes>(getTheme)

  useEffect(() => {
    localStorage.setItem(StorageKey, theme)
    document.documentElement.setAttribute('data-theme', theme)
  }, [theme])

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        supportedThemes,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
