import { cn } from '@bem-react/classname'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { AnimatePresence, motion } from 'framer-motion'
import { FC, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  CreateInvoiceModel,
  IInvoiceResponse,
} from '@/core/services/Admin/AdminModel'
import {
  adminSelector,
  merchantsSelector,
} from '@/core/services/Admin/store/AppSelector'
import {
  createAdminInvoiceThunk,
  updateAdminInvoiceThunk,
} from '@/core/services/Admin/store/AppSlice'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import yup from '@/utils/yup-extended'

import { Field } from '@/components/Form/Field'
import { Select } from '@/components/Form/Select'
import { Button } from '@/components/UI/Button'
import { Icon } from '@/components/UI/Icon'

import { userSelector } from '@/modules/Auth/store/AuthSelect'

import { InvoiceModel } from '../../models/InvoiceModel'
import { createInvoiceThunk } from '../../store/InvoiceSlice'
import './CreateInvoice.scss'

const cnCreateInvoice = cn('CreateInvoice')

interface ICreateInvoice {
  close: () => void
  onSuccess: () => void
  open?: boolean
  invoice?: IInvoiceResponse | null
}

export const CreateInvoice: FC<ICreateInvoice> = ({
  close,
  onSuccess,
  invoice,
  open,
}) => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const alert = useAlert()

  const user = useAppSelector(userSelector)
  const merchants = useAppSelector(merchantsSelector)
  const isAdmin = useAppSelector(adminSelector)

  useEffect(() => {
    if (invoice) {
      setValue('monthlyServiceFee', invoice.monthlyServiceFee)
      setValue(
        'replenishmentLiquidityBalance',
        invoice.replenishmentLiquidityBalance,
      )
      setValue(
        'replenishmentServiceBalance',
        invoice.replenishmentServiceBalance,
      )
    } else {
      reset(new CreateInvoiceModel())
    }
  }, [invoice])

  const schema = yup
    .object({
      replenishmentLiquidityBalance: yup.string().trim().nullable(),
      replenishmentServiceBalance: yup.string().trim().nullable(),
    })
    .required()

  const {
    handleSubmit,
    control,
    reset,
    register,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<CreateInvoiceModel>({
    mode: 'onBlur',
    defaultValues: new CreateInvoiceModel(),
    resolver: yupResolver(schema),
  })

  const updateAdminInvoice = async (form: CreateInvoiceModel) => {
    if (!invoice) return

    try {
      await dispatch(
        updateAdminInvoiceThunk({
          ...invoice,
          monthlyServiceFee: form.monthlyServiceFee
            ? form.monthlyServiceFee
            : 0,
          replenishmentLiquidityBalance: form.replenishmentLiquidityBalance,
          replenishmentServiceBalance: form.replenishmentServiceBalance,
        }),
      ).unwrap()

      alert.success('Invoice updated')
      onSuccess()
      close()
    } catch (error) {
      console.log('updateAdminInvoice', error)
    }
  }

  const onSubmit = async (form: CreateInvoiceModel) => {
    if (!user && !isAdmin) return

    let data: CreateInvoiceModel = {
      replenishmentLiquidityBalance: form.replenishmentLiquidityBalance
        ? +form.replenishmentLiquidityBalance
        : null,
      replenishmentServiceBalance: form.replenishmentServiceBalance
        ? +form.replenishmentServiceBalance
        : null,
    }

    console.log(form)

    if (isAdmin) {
      data = {
        ...data,
        users_permissions_user: {
          ...data.users_permissions_user,
          connect: [{ id: form.userId || 0, position: { end: true } }],
          disconnect: [],
        },
        payment_status: {
          ...data.payment_status,
          connect: [{ id: 3, position: { end: true } }],
          disconnect: [],
        },
        editable: true,
        monthlyServiceFee: 0,
        invoiceDate: dayjs().format('YYYY-MM-DD'),
        dueDate: dayjs().add(5, 'day').format('YYYY-MM-DD'),
      }
    }
    try {
      if (isAdmin) {
        await dispatch(createAdminInvoiceThunk(data)).unwrap()
      } else {
        await dispatch(createInvoiceThunk(data as InvoiceModel)).unwrap()
      }
      alert.success(t('Invoice created'))
      onSuccess()
    } catch (error) {
      console.log('createInvoiceSliceThunk', error)
    }
  }

  return (
    <AnimatePresence>
      {open && (
        <div className={cnCreateInvoice()}>
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={cnCreateInvoice('overlay')}
            onClick={() => close()}
          ></motion.div>
          <motion.div
            initial={{ x: '100%' }}
            exit={{ x: '100%' }}
            animate={{ x: 0 }}
            className={cnCreateInvoice('wrap')}
          >
            <div className={cnCreateInvoice('top')}>
              <div className={cnCreateInvoice('title')}>Create Invoice</div>
              <button type='button' onClick={close}>
                <Icon name='close' />
              </button>
            </div>

            <form
              onSubmit={handleSubmit(invoice ? updateAdminInvoice : onSubmit)}
              className={cnCreateInvoice('form')}
            >
              <Field
                {...register('replenishmentLiquidityBalance')}
                placeholder={t('Liquidity Balance')}
                label={t('Liquidity Balance')}
                errors={
                  errors &&
                  errors.replenishmentLiquidityBalance &&
                  errors.replenishmentLiquidityBalance.message
                }
                inputMode='decimal'
                mb='xl'
              />
              <Field
                {...register('replenishmentServiceBalance')}
                placeholder={t('Service Balance')}
                label={t('Service Balance')}
                errors={
                  errors &&
                  errors.replenishmentServiceBalance &&
                  errors.replenishmentServiceBalance.message
                }
                inputMode='decimal'
              />

              {isAdmin && (
                <Field
                  {...register('monthlyServiceFee')}
                  placeholder={t('Monthly Service Fee')}
                  label={t('monthly Service Fee')}
                  errors={
                    errors &&
                    errors.monthlyServiceFee &&
                    errors.monthlyServiceFee.message
                  }
                  inputMode='decimal'
                />
              )}

              {isAdmin && merchants && !invoice && (
                <Controller
                  control={control}
                  name='userId'
                  render={({ field }) => (
                    <Select
                      options={merchants.results}
                      value={field.value}
                      onChange={v => field.onChange(v['id'])}
                      optionLabel='username'
                      label={t('User')}
                      placeholder={t('Choose user')}
                    />
                  )}
                />
              )}

              <Button
                type='submit'
                isLoading={isSubmitting}
                className={cnCreateInvoice('submit')}
              >
                {invoice ? 'Save' : 'Create'}
              </Button>
            </form>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}
