/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { IDefaultResponse } from '@/core/interfaces'
import { ThemeType } from '@/core/types'

import { IBalance, IDailyHistory, IHistory } from '../AppModel'
import AppService from '../AppService'

interface AppState {
  balances: IBalance | null
  theme: ThemeType
  history: IDefaultResponse<IHistory[]> | null
  dailyHistory: IDefaultResponse<IDailyHistory[]> | null
}

const initialState: AppState = {
  theme: 'light',
  balances: null,
  history: null,
  dailyHistory: null,
}

export const getBalancesThunk = createAsyncThunk(
  'app/getBalancesThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await AppService.getBalances()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getAccountHistoryThunk = createAsyncThunk(
  'app/getAccountHistoryThunk',
  async (query: string, { rejectWithValue }) => {
    try {
      return await AppService.getAccountHistory(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getAccountDailyHistoryThunk = createAsyncThunk(
  'app/getAccountDailyHistoryThunk',
  async (query: string, { rejectWithValue }) => {
    try {
      return await AppService.getAccountDailyHistory(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeTheme(state, action: PayloadAction<ThemeType>) {
      state.theme = action.payload
    },
  },

  extraReducers: builder => {
    builder

      .addCase(getBalancesThunk.fulfilled, (state, action) => {
        state.balances = action.payload
      })
      .addCase(getBalancesThunk.rejected, state => {
        state.balances = null
      })

      .addCase(
        getAccountHistoryThunk.fulfilled,
        (state, action: PayloadAction<IDefaultResponse<IHistory[]>>) => {
          state.history = action.payload
        },
      )
      .addCase(getAccountHistoryThunk.rejected, state => {
        state.history = null
      })

      .addCase(
        getAccountDailyHistoryThunk.fulfilled,
        (state, action: PayloadAction<IDefaultResponse<IDailyHistory[]>>) => {
          state.dailyHistory = action.payload
        },
      )
      .addCase(getAccountDailyHistoryThunk.rejected, state => {
        state.dailyHistory = null
      })
  },
})

export const { actions, reducer } = appSlice
export const { changeTheme } = actions
