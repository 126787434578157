import { cn } from '@bem-react/classname'
import { ColumnDef } from '@tanstack/react-table'
import qs from 'qs'
import queryString from 'query-string'
import { FC, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Card } from '@/components/UI/Card'
import { DataGrid } from '@/components/UI/DataGrid'
import { Loading } from '@/components/UI/Loading'
import { Title } from '@/components/UI/Title'

import './Cards.scss'
import { ICardResponse } from './models/CardModel'
import { cardsSelector } from './store/CardsSelector'
import { getCardsThunk } from './store/CardsSlice'

const cnCards = cn('Cards')

export const Cards: FC = () => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const { search } = useLocation()

  const cardList = useAppSelector(cardsSelector)

  const [isLoading, setLoading] = useState<boolean>(true)
  const [cards, setCards] = useState<ICardResponse[]>([])

  useEffect(() => {
    if (search) {
      const query = queryString.parse(search)

      if (query && query.page) {
        getCards(+query.page)
      }
    } else {
      getCards(1)
    }
  }, [search])

  useEffect(() => {
    if (cardList && cardList.items && cardList.items.length) {
      setCards(cardList.items)
    }
  }, [cardList])

  const columns = useMemo<ColumnDef<ICardResponse>[]>(
    () => [
      {
        id: 'id',
        header: 'Card ID',
        accessorKey: 'id',
        enableSorting: false,
        // maxSize: 150,
      },

      {
        id: 'balance',
        header: 'Balance',
        // accessorKey: 'balance',
        cell: ({ row }) =>
          row.original.balance ? '$' + row.original.balance : '$0',
        enableSorting: false,
        // maxSize: 150,
      },

      {
        id: 'cardName',
        header: 'Card Name',
        accessorKey: 'card_name',
        enableSorting: false,
        // maxSize: 150,
      },

      {
        id: 'cardType',
        header: 'Card Type',
        accessorKey: 'card_type',
        enableSorting: false,
        // maxSize: 150,
      },

      {
        id: 'last4',
        header: 'Card Mask',
        accessorKey: 'last4',
        cell: ({ row }) =>
          row.original.last4 ? '**** **** ****' + row.original.last4 : '',
        enableSorting: false,
        // maxSize: 150,
      },

      // {
      //   id: 'status',
      //   header: 'Status',
      //   // accessorKey: 'status',
      //   cell: ({ row }) => (
      //     <Status type={row.original.status as StatusType}>
      //       {row.original.status}
      //     </Status>
      //   ),
      //   enableSorting: false,
      //   // maxSize: 150,
      // },
    ],
    [],
  )

  const getCards = async (page = 1) => {
    const query = qs.stringify({
      page,
      limit: 10,
    })
    try {
      await dispatch(getCardsThunk(query)).unwrap()
      setLoading(false)
    } catch (error) {
      console.log('getCards', error)
      setLoading(false)
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('cards-title')}</title>
        <meta name='description' content={t('cards-description')} />
      </Helmet>
      <Card className={cnCards()}>
        <div className={cnCards('top')}>
          <Title>{t('Cards')}</Title>
        </div>

        {isLoading ? (
          <Loading type='logo' minHeight='300px' />
        ) : (
          <DataGrid data={cards} columns={columns} meta={cardList?.meta} />
        )}
      </Card>
    </>
  )
}
