import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import './Card.scss'

const cnCard = cn('Card')

interface ICard
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
  color?: 'dark' | 'light'
}

export const Card: FC<ICard> = ({ children, className, color = 'dark' }) => {
  return (
    <div
      className={cnCard(
        {
          dark: color === 'dark',
          light: color === 'light',
        },
        [className],
      )}
    >
      {children}
    </div>
  )
}
