import { cn } from '@bem-react/classname'
import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import qs from 'qs'
import queryString from 'query-string'
import { FC, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { IHistory } from '@/core/services/App/AppModel'
import { historySelector } from '@/core/services/App/store/AppSelector'
import { getAccountHistoryThunk } from '@/core/services/App/store/AppSlice'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Card } from '@/components/UI/Card'
import { DataGrid } from '@/components/UI/DataGrid'
import { Loading } from '@/components/UI/Loading'
import { NoData } from '@/components/UI/NoData'
import { Status } from '@/components/UI/Status'
import { Title } from '@/components/UI/Title'

import './History.scss'

const cnHistory = cn('History')

export const History: FC = () => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const { slug } = useParams<{ slug: string }>()

  const history = useAppSelector(historySelector)

  const [isLoading, setLoading] = useState(true)
  const [historyList, setHistoryList] = useState<IHistory[]>([])

  useEffect(() => {
    if (history) {
      const data = history.items.map(el => {
        const arr = [
          {
            key: 'card-issue',
            value: 'Fee card issue',
          },
          {
            key: 'refund !card-issue',
            value: 'Refund fee card issue',
          },
          {
            key: 'kyc',
            value: 'KYC fee',
          },
          {
            key: 'refund !kyc',
            value: 'Refund KYC fee',
          },
        ]

        const desc = arr.find(item => item.key === el.Description)

        return {
          ...el,
          Description: desc
            ? desc.value
            : slug === 'drop-balance-to-card'
            ? el.Description
            : '',
        }
      })

      setHistoryList(data)
    }
  }, [history])

  useEffect(() => {
    if (search) {
      const query = queryString.parse(search)

      if (query && query.page) {
        getHistory(+query.page)
      }
    } else {
      getHistory(1)
    }
  }, [search])

  const getHistory = async (page: number) => {
    const query = qs.stringify({
      page,
      limit: 10,
      filter: slug?.replaceAll('-', '_'),
    })

    try {
      await dispatch(getAccountHistoryThunk(query)).unwrap()

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('getHistoryThunk', error)
    }
  }

  const columns = useMemo<ColumnDef<IHistory>[]>(
    () => [
      {
        id: 'description',
        header: 'Description',
        // accessorKey: 'Description',
        cell: ({ row }) => (
          <>
            {row.original.Type === 'fee' ? (
              <div>{row.original.Description}</div>
            ) : (
              <div>
                <div className={cnHistory('value')}>
                  {t('Card replenishment')}
                </div>
                <div className={cnHistory('small')}>
                  {row.original.Description}
                </div>
              </div>
            )}
          </>
        ),
        enableSorting: false,
        maxSize: 150,
      },

      {
        id: 'amount',
        header: 'Amount',
        cell: ({ row }) => (
          <Status
            type={
              String(row.original.Amount).includes('-') ? 'ERROR' : 'COMPLETE'
            }
          >
            {row.original.Amount}
          </Status>
        ),
        enableSorting: false,
        maxSize: 150,
      },

      {
        id: 'time',
        header: 'Time',
        cell: ({ row }) => dayjs(row.original.Time).format('DD.MM.YYYY HH:mm'),
        enableSorting: false,
        maxSize: 150,
      },
    ],
    [],
  )

  return (
    <>
      <Helmet>
        <title>{t('History-title')}</title>
        <meta name='description' content={t('History-description')} />
      </Helmet>
      <div className={cnHistory()}>
        <Card
          className={cnHistory('card', {
            empty: !!history,
          })}
        >
          <div className={cnHistory('header')}>
            <Title size='md' className={cnHistory('title')}>
              {t('History')}{' '}
              {slug === 'drop-balance-to-card'
                ? t('liquidity balance')
                : 'service balance'}
            </Title>
            {/* <div className={cnHistory('actions')}>
              <Button
                iconPosition='left'
                icon={<Icon name='plus' fill='white' />}
                onClick={() => setCreateInvoice(true)}
              >
                {t('Create invoice')}
              </Button>
              <Button
                iconPosition='left'
                icon={<Icon name='filter' />}
                color='gray'
              >
                {t('Filter')}
              </Button>
            </div> */}
          </div>

          {isLoading ? (
            <Loading type='logo' minHeight='300px' />
          ) : (
            <>
              {history && history.items && history.items.length > 0 ? (
                <DataGrid
                  data={historyList}
                  columns={columns}
                  autoHeightMax={800}
                  meta={history.meta}
                />
              ) : (
                <NoData
                  icon='invoice'
                  text={t('There have not been any History yet')}
                />
              )}
            </>
          )}
        </Card>
      </div>
    </>
  )
}
