import { FC, ForwardedRef, ReactNode, RefAttributes, forwardRef } from 'react'
import {
  ScrollbarProps,
  Scrollbars,
  positionValues,
} from 'react-custom-scrollbars'

import { cn } from '@bem-react/classname'

import './CustomScrollbars.scss'

const cnScrollbars = cn('CustomScrollbars')

interface CustomScrollbarsSchema extends ScrollbarProps {
  children?: ReactNode
  autoHeight?: boolean
  autoHeightMin?: number
  autoHeightMax?: number
  handleScrollFrame?: (values: positionValues) => void
}

export const CustomScrollbars: FC<
  Omit<CustomScrollbarsSchema, 'ref'> & RefAttributes<Scrollbars>
> = forwardRef(
  (props: CustomScrollbarsSchema, ref: ForwardedRef<Scrollbars>) => {
    return (
      <Scrollbars
        ref={ref}
        hideTracksWhenNotNeeded={true}
        autoHeight={props.autoHeight}
        autoHeightMin={props.autoHeightMin}
        autoHeightMax={props.autoHeightMax}
        className={cnScrollbars()}
        onScrollFrame={props.handleScrollFrame}
        renderTrackHorizontal={props => (
          <div
            {...props}
            className={cnScrollbars('track', { horizontal: true })}
          />
        )}
        renderTrackVertical={props => (
          <div
            {...props}
            className={cnScrollbars('track', { vertical: true })}
          />
        )}
        renderThumbHorizontal={props => (
          <div
            {...props}
            className={cnScrollbars('thumb', { horizontal: true })}
          />
        )}
        renderThumbVertical={props => (
          <div
            {...props}
            className={cnScrollbars('thumb', { vertical: true })}
          />
        )}
        renderView={props => (
          <div {...props} className={cnScrollbars('view')} />
        )}
        {...props}
      >
        {props.children}
      </Scrollbars>
    )
  },
)
