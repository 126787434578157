import { cn } from '@bem-react/classname'
import { yupResolver } from '@hookform/resolvers/yup'
import { AnimatePresence, motion } from 'framer-motion'
import { FC } from 'react'
import { useAlert } from 'react-alert'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from '@/core/store/hooks'

import yup from '@/utils/yup-extended'

import { Field } from '@/components/Form/Field'
import { Button } from '@/components/UI/Button'
import { Icon } from '@/components/UI/Icon'

import { RegisterModel } from '@/modules/Auth/models/AuthModel'
import { addUserThunk } from '@/modules/Auth/store/AuthSlice'

import './AddUser.scss'

const cnAddUser = cn('AddUser')

interface IAddUser {
  close: () => void
  onSuccess: () => void
  open?: boolean
}

export const AddUser: FC<IAddUser> = ({ close, onSuccess, open }) => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const alert = useAlert()

  const schema = yup
    .object({
      username: yup.string().trim().required(),
      email: yup.string().trim().email().required(),
      password: yup.string().trim().required(),
      phone: yup.string().trim().nullable(),
      country: yup.string().trim().nullable(),
      registrationNumber: yup.string().trim().nullable(),
    })
    .required()

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<RegisterModel>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const onSubmit = async (form: RegisterModel) => {
    try {
      await dispatch(addUserThunk(form)).unwrap()

      alert.success(t('Invoice created'))

      onSuccess()
      close()
    } catch (error) {
      console.log('AddUserSliceThunk', error)
    }
  }

  return (
    <AnimatePresence>
      {open && (
        <div className={cnAddUser()}>
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={cnAddUser('overlay')}
            onClick={() => close()}
          ></motion.div>
          <motion.div
            initial={{ x: '100%' }}
            exit={{ x: '100%' }}
            animate={{ x: 0 }}
            className={cnAddUser('wrap')}
          >
            <div className={cnAddUser('top')}>
              <div className={cnAddUser('title')}>Create Invoice</div>
              <button type='button' onClick={close}>
                <Icon name='close' />
              </button>
            </div>

            <form
              onSubmit={handleSubmit(onSubmit)}
              className={cnAddUser('form')}
            >
              <Field
                {...register('username')}
                placeholder={t('Username')}
                label={t('Username')}
                errors={errors && errors.username && errors.username.message}
                mb='xl'
              />

              <Field
                {...register('email')}
                placeholder={t('Email')}
                label={t('Email')}
                errors={errors && errors.email && errors.email.message}
                mb='xl'
              />

              <Field
                {...register('phone')}
                placeholder={t('Phone')}
                label={t('Phone')}
                errors={errors && errors.phone && errors.phone.message}
                mb='xl'
              />

              <Field
                {...register('country')}
                placeholder={t('Country')}
                label={t('Country')}
                errors={errors && errors.country && errors.country.message}
                mb='xl'
              />

              <Field
                {...register('registrationNumber')}
                placeholder={t('Registration Number')}
                label={t('Registration Number')}
                errors={
                  errors &&
                  errors.registrationNumber &&
                  errors.registrationNumber.message
                }
                mb='xl'
              />

              <Field
                {...register('password')}
                placeholder={t('Password')}
                label={t('Password')}
                errors={errors && errors.password && errors.password.message}
                mb='xl'
              />

              <Button
                type='submit'
                isLoading={isSubmitting}
                className={cnAddUser('submit')}
              >
                Create
              </Button>
            </form>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}
