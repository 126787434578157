import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  AdminLoginModel,
  CreateInvoiceModel,
  IDefaultAdminResponse,
  IFee,
  IInvoiceResponse,
  IMerchantResponse,
  IUserAdmin,
} from '../AdminModel'
import AdminService from '../AdminService'

interface AdminState {
  fee: IFee | null
  isAdmin: boolean
  userAdmin: IUserAdmin | null
  merchants: IDefaultAdminResponse<IMerchantResponse[]> | null
  invoices: IDefaultAdminResponse<IInvoiceResponse[]> | null
}

const initialState: AdminState = {
  fee: null,
  isAdmin: false,
  userAdmin: null,
  merchants: null,
  invoices: null,
}

export const getFeeThunk = createAsyncThunk(
  'admin/getFeeThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await AdminService.getFee()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getUserAdminThunk = createAsyncThunk(
  'admin/getUserAdminThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await AdminService.getUser()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getAdminLoginThunk = createAsyncThunk(
  'admin/getAdminLoginThunk',
  async (data: AdminLoginModel, { rejectWithValue }) => {
    try {
      return await AdminService.login(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getMerchantsThunk = createAsyncThunk(
  'admin/getMerchantsThunk',
  async (query: string, { rejectWithValue }) => {
    try {
      return await AdminService.getMerchants(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getAdminInvoicesThunk = createAsyncThunk(
  'admin/getAdminInvoicesThunk',
  async (query: string, { rejectWithValue }) => {
    try {
      return await AdminService.getInvoices(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const updateAdminInvoiceThunk = createAsyncThunk(
  'admin/updateAdminInvoiceThunk',
  async (data: IInvoiceResponse, { rejectWithValue }) => {
    try {
      return await AdminService.updateAdminInvoice(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const createAdminInvoiceThunk = createAsyncThunk(
  'admin/updateAdminInvoiceThunk',
  async (data: CreateInvoiceModel, { rejectWithValue }) => {
    try {
      return await AdminService.createAdminInvoice(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder

      .addCase(getFeeThunk.fulfilled, (state, action) => {
        state.fee = action.payload
      })
      .addCase(getFeeThunk.rejected, state => {
        state.fee = null
      })

      .addCase(getAdminLoginThunk.fulfilled, state => {
        state.isAdmin = true
      })
      .addCase(getAdminLoginThunk.rejected, state => {
        state.isAdmin = false
      })

      .addCase(getUserAdminThunk.fulfilled, (state, action) => {
        state.userAdmin = action.payload
        state.isAdmin = true
      })
      .addCase(getUserAdminThunk.rejected, state => {
        state.userAdmin = null
        state.isAdmin = false
      })

      .addCase(getMerchantsThunk.fulfilled, (state, action) => {
        state.merchants = action.payload
      })
      .addCase(getMerchantsThunk.rejected, state => {
        state.merchants = null
      })

      .addCase(getAdminInvoicesThunk.fulfilled, (state, action) => {
        state.invoices = action.payload
      })
      .addCase(getAdminInvoicesThunk.rejected, state => {
        state.invoices = null
      })
  },
})

export const { actions, reducer } = adminSlice
