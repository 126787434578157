import { combineReducers } from '@reduxjs/toolkit'

import { reducer as adminReducer } from '@/core/services/Admin/store/AppSlice'
import { reducer as appReducer } from '@/core/services/App/store/AppSlice'
import { reducer as toastReducer } from '@/core/services/Toast/store/ToastSlice'

import { reducer as authReducer } from '@/modules/Auth/store/AuthSlice'
import { reducer as cardsReducer } from '@/modules/Cards/store/CardsSlice'
import { reducer as clientsReducer } from '@/modules/Clients/store/ClientsSlice'
import { reducer as invoiceReducer } from '@/modules/Invoices/store/InvoiceSlice'

const rootReducer = combineReducers({
  appReducer,
  toastReducer,
  authReducer,
  adminReducer,
  cardsReducer,
  invoiceReducer,
  clientsReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
