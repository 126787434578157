import { FC } from 'react'
import { Provider as AlertProvider, positions } from 'react-alert'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'

import i18n from '@/core/i18n'

import { AlertTemplate } from './components/UI/AlertTemplate'
import { ThemeProvider } from './core/providers/ThemeProvider'
import { store } from './core/store'
import { AppCunex } from './modules'

const options = {
  timeout: 5000,
  position: positions.TOP_CENTER,
}

export const App: FC = () => {
  return (
    <ThemeProvider>
      <AlertProvider template={AlertTemplate} {...options}>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <AppCunex />
          </I18nextProvider>
        </Provider>
      </AlertProvider>
    </ThemeProvider>
  )
}
