import { AxiosResponse } from 'axios'

import { http } from '@/utils/http'

import {
  AuthModel,
  ChangePasswordModel,
  IUser,
  IUserData,
  IUserRequest,
  RegisterModel,
  UserModel,
} from './models/AuthModel'

class AuthService {
  async login(data: AuthModel): Promise<IUserData> {
    const res = await http.post<AuthModel, AxiosResponse<IUserData>>(
      `/auth/local`,
      data,
    )

    localStorage.setItem('token', res.data.jwt)

    return res.data
  }

  async getUser(): Promise<IUser> {
    const res = await http.get<null, AxiosResponse<IUser>>(
      `/users/me?populate=*`,
    )

    return res.data
  }

  async getUsers(query: string): Promise<IUser[]> {
    const res = await http.get<null, AxiosResponse<IUser[]>>(
      `/users${query ? '?' + query : ''}`,
    )

    return res.data
  }

  async updateUser(data: UserModel): Promise<IUser> {
    const res = await http.put<IUserRequest, AxiosResponse<IUser>>(
      `/users/${data.id}`,
      {
        data: {
          password: data.password,
        },
      },
    )

    return res.data
  }

  async addUser(data: RegisterModel): Promise<IUser> {
    const res = await http.post<RegisterModel, AxiosResponse<IUser>>(
      `/auth/local/register`,
      data,
    )

    return res.data
  }

  async changePassword(data: ChangePasswordModel): Promise<IUserData> {
    const res = await http.post<ChangePasswordModel, AxiosResponse<IUserData>>(
      `/account/change-password`,
      data,
    )

    return res.data
  }
}

export default new AuthService()
