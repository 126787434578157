import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, useState } from 'react'
import 'swiper/css'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

import { IMerchantResponse } from '@/core/services/Admin/AdminModel'

import { Button } from '@/components/UI/Button'
import { Card } from '@/components/UI/Card'
import { Icon } from '@/components/UI/Icon'

import './MerchantSlider.scss'

const cnMerchantSlider = cn('MerchantSlider')

interface IMerchantSlider
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  merchants: IMerchantResponse[]
}

export const MerchantSlider: FC<IMerchantSlider> = ({
  className,
  merchants,
}) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)

  return (
    <Swiper
      modules={[Navigation, Pagination, A11y]}
      spaceBetween={18}
      slidesPerView={3}
      pagination={{
        clickable: true,
        el: '.MerchantSlider-pagination',

        renderBullet: function (index, className) {
          return '<div class="' + className + '"></div>'
        },
      }}
      className={cnMerchantSlider(null, [className])}
      onSwiper={swiper => setSwiper(swiper)}
    >
      {merchants.map(merchant => (
        <SwiperSlide key={merchant.id}>
          <Card color='light' className={cnMerchantSlider('card')}>
            <div className={cnMerchantSlider('name')}>{merchant.username}</div>
            <div className={cnMerchantSlider('balance')}>
              $ L {merchant.balance | 0}
            </div>
            <div className={cnMerchantSlider('balance')}>
              $ S {merchant.serviceBalance | 0}
            </div>
          </Card>
        </SwiperSlide>
      ))}

      {swiper && merchants.length >= 3 && (
        <div className={cnMerchantSlider('nav')}>
          <Button color='gray' onClick={() => swiper.slidePrev()}>
            <Icon name='arrow-left' />
          </Button>
          <div className={cnMerchantSlider('pagination')}></div>
          <Button color='gray' onClick={() => swiper.slideNext()}>
            <Icon name='arrow-right' />
          </Button>
        </div>
      )}
    </Swiper>
  )
}
