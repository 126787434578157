import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store/store'

const user = (state: RootState) => state.authReducer
export const userSelector = createSelector(
  user,
  authReducer => authReducer.user,
)

const users = (state: RootState) => state.authReducer
export const usersSelector = createSelector(
  users,
  authReducer => authReducer.users,
)

const isAuth = (state: RootState) => state.authReducer
export const authSelector = createSelector(
  isAuth,
  authReducer => authReducer.isAuth,
)
