import { cn } from '@bem-react/classname'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  getAdminLoginThunk,
  getUserAdminThunk,
} from '@/core/services/Admin/store/AppSlice'
import { useAppDispatch } from '@/core/store/hooks'

import { Field } from '@/components/Form/Field'
import { Logo } from '@/components/Layout/Logo'
import { Button } from '@/components/UI/Button'

import './Login.scss'
import { AuthModel } from './models/AuthModel'
import { getUserThunk, loginThunk } from './store/AuthSlice'

const cnLogin = cn('Login')

export const Login: FC = () => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [admin, setAdmin] = useState<boolean>(false)

  useEffect(() => {
    if (pathname === '/admin') setAdmin(true)
  }, [pathname])

  console.log(admin)

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<AuthModel>({
    mode: 'onChange',
  })

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (token) getUser()
  }, [])

  const getUser = async () => {
    try {
      if (admin) {
        await dispatch(getUserAdminThunk()).unwrap()
      } else {
        await dispatch(getUserThunk()).unwrap()
      }

      navigate(`/${admin ? 'a' : 'c'}/dashboard`)
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async (data: AuthModel) => {
    localStorage.removeItem('token')
    try {
      if (admin) {
        await dispatch(
          getAdminLoginThunk({
            email: data.identifier,
            password: data.password,
          }),
        ).unwrap()
      } else {
        await dispatch(loginThunk(data)).unwrap()
      }

      await getUser()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className={cnLogin()}>
        <div className={cnLogin('top')}>
          <Logo />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field
            {...register('identifier')}
            placeholder={t('login-placeholder-email')}
            mb='xl'
          />
          <Field
            {...register('password')}
            placeholder={t('login-placeholder-password')}
            mb='xl'
            type='password'
          />
          <Button
            type='submit'
            className={cnLogin('btn')}
            isLoading={isSubmitting}
          >
            {t('login-btn')}
          </Button>
        </form>
      </div>
    </>
  )
}
