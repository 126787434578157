import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { Icon } from '../../UI/Icon'
import './Menu.scss'

const cnMenu = cn('Menu')

interface IMenuItem {
  title: string
  href: string
  icon: string
}

interface IMenu {
  className?: string
  data: IMenuItem[]
  isMobile?: boolean
}

export const Menu: FC<IMenu> = ({ className, data, isMobile }) => {
  const { t } = useTranslation('translation')
  return (
    <nav className={cnMenu(null, [className])}>
      <ul className={cnMenu('list', { mobile: isMobile })}>
        {data.map((el, i) => (
          <li key={i} className={cnMenu('item')}>
            <NavLink className={cnMenu('link')} to={el.href}>
              <Icon name={el.icon} width={24} height={24} />
              <span className={cnMenu('title')}>{t(el.title)}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}
