import { AxiosResponse } from 'axios'

import { http } from '@/utils/http'

import {
  AdminLoginModel,
  CreateInvoiceModel,
  IAdminLoginResponse,
  IDefaultAdminResponse,
  IFee,
  IInvoiceResponse,
  IMerchantResponse,
  IRequestBalance,
  IRequestFee,
  IUserAdmin,
} from './AdminModel'

class AdminService {
  async getFee(): Promise<IFee> {
    const res = await http.get(`/admin/fee`)

    return res.data
  }

  async updateFee(data: IRequestFee): Promise<boolean> {
    const res = await http.put<
      Pick<IRequestFee, 'amount'>,
      AxiosResponse<boolean>
    >(`/admin/fee/${data.name}`, { amount: data.amount })

    return res.data
  }

  async addUserBalance(data: IRequestBalance): Promise<boolean> {
    const res = await http.post<
      Pick<IRequestBalance, 'amount'>,
      AxiosResponse<boolean>
    >(`/admin/user/${data.email}/balance`, { amount: data.amount })

    return res.data
  }

  async addUserServiceBalance(data: IRequestBalance): Promise<boolean> {
    const res = await http.post<
      Pick<IRequestBalance, 'amount'>,
      AxiosResponse<boolean>
    >(`/admin/user/${data.email}/service-balance`, { amount: data.amount })

    return res.data
  }

  async login(data: AdminLoginModel): Promise<IAdminLoginResponse> {
    const res = await http.post<
      AdminLoginModel,
      AxiosResponse<IAdminLoginResponse>
    >(`/admin/login`, data)

    localStorage.setItem('token', res.data.data.token)
    localStorage.setItem('admin', 'true')

    return res.data
  }

  async getUser(): Promise<IUserAdmin> {
    const res = await http.get(`/admin/users/me`)

    return res.data
  }

  async getMerchants(
    query: string,
  ): Promise<IDefaultAdminResponse<IMerchantResponse[]>> {
    const res = await http.get<
      null,
      AxiosResponse<IDefaultAdminResponse<IMerchantResponse[]>>
    >(
      `/content-manager/collection-types/plugin::users-permissions.user${
        query ? '?' + query : ''
      }`,
    )

    return res.data
  }

  async getInvoices(
    query: string,
  ): Promise<IDefaultAdminResponse<IInvoiceResponse[]>> {
    const res = await http.get<
      null,
      AxiosResponse<IDefaultAdminResponse<IInvoiceResponse[]>>
    >(
      `/content-manager/collection-types/api::invoice.invoice${
        query ? '?' + query : ''
      }`,
    )

    return res.data
  }

  async updateAdminInvoice(data: IInvoiceResponse): Promise<IInvoiceResponse> {
    const res = await http.put(
      `/content-manager/collection-types/api::invoice.invoice/${data.id}`,
      data,
    )

    return res.data
  }

  async createAdminInvoice(
    data: CreateInvoiceModel,
  ): Promise<IInvoiceResponse> {
    const res = await http.post<
      CreateInvoiceModel,
      AxiosResponse<IInvoiceResponse>
    >(`/content-manager/collection-types/api::invoice.invoice`, data)

    return res.data
  }
}

export default new AdminService()
