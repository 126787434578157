import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '../Icon'
import './Pagination.scss'

const cnPagination = cn('Pagination')

interface IPagination {
  paginate?: (pageNumber: number) => void
  currentPage: number
  maxPages: number
  visiblePageLinks?: number
  className?: string
  totalItems?: number
}

export const Pagination: FC<IPagination> = ({
  paginate,
  currentPage,
  maxPages,
  visiblePageLinks = 5,
  totalItems,
  className,
}) => {
  const { t } = useTranslation('translation')

  const pageNumbers: number[] = []
  const halfVisible = Math.floor(visiblePageLinks / 2)

  // Определяем начальный и конечный индексы для отображаемых ссылок
  let startPage = Math.max(1, currentPage - halfVisible)
  const endPage = Math.min(currentPage + halfVisible, maxPages)

  // Корректируем, если видимых страниц меньше, чем допустимо
  if (endPage - startPage < visiblePageLinks - 1) {
    startPage = Math.max(1, endPage - visiblePageLinks + 1)
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i)
  }
  return (
    <nav className={cnPagination(null, [className])}>
      <ul className={cnPagination('list')}>
        <li className={cnPagination('item')}>
          <button
            onClick={() =>
              currentPage > 1 && paginate && paginate(currentPage - 1)
            }
            disabled={currentPage === 1}
            className={cnPagination('link')}
          >
            <Icon name='chevron-left' />
            {t('Prev')}
          </button>
        </li>
        {pageNumbers.map(number => (
          <li key={number} className={cnPagination('item')}>
            <button
              onClick={() => paginate && paginate(number)}
              className={cnPagination('link', {
                active: number === currentPage,
              })}
            >
              {number}
            </button>
          </li>
        ))}
        <li className={cnPagination('item')}>
          <button
            onClick={() =>
              currentPage < maxPages && paginate && paginate(currentPage + 1)
            }
            disabled={currentPage === maxPages}
            className={cnPagination('link')}
          >
            {t('Next')}
            <Icon name='chevron-right' />
          </button>
        </li>
      </ul>

      {totalItems && (
        <div className={cnPagination('total')}>Total: {totalItems} lines</div>
      )}
    </nav>
  )
}
