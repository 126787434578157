import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { AlertTemplateProps } from 'react-alert'

import { Icon } from '../Icon'
import './AlertTemplate.scss'

const cnAlertTemplate = cn('AlertTemplate')

interface IAlertTemplate extends AlertTemplateProps {
  className?: string
}

export const AlertTemplate: FC<IAlertTemplate> = ({
  className,
  style,
  options,
  message,
  close,
}: IAlertTemplate) => {
  return (
    <div
      className={cnAlertTemplate(
        {
          info: options.type === 'info',
          success: options.type === 'success',
          error: options.type === 'error',
        },
        [className],
      )}
    >
      <div className={cnAlertTemplate('wrap')} style={style}>
        <div className={cnAlertTemplate('icon')}>
          {options.type === 'info' && (
            <Icon name='circle-info' fill='#fff' width={20} height={20} />
          )}
          {options.type === 'success' && (
            <Icon name='circle-check' fill='#fff' width={20} height={20} />
          )}
          {options.type === 'error' && (
            <Icon name='circle-error' fill='#fff' width={20} height={20} />
          )}
        </div>

        <div className={cnAlertTemplate('message')}>{message}</div>

        <button onClick={close} className={cnAlertTemplate('btn')}>
          <Icon name='close' fill='#ffffff' width={16} height={16} />
        </button>
      </div>
    </div>
  )
}
