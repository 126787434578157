import { cn } from '@bem-react/classname'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import yup from '@/utils/yup-extended'

import { Field } from '@/components/Form/Field'
import { Button } from '@/components/UI/Button'
import { Card } from '@/components/UI/Card'
import { Icon } from '@/components/UI/Icon'
import { Title } from '@/components/UI/Title'

import { ChangePasswordModel } from '../Auth/models/AuthModel'
import { userSelector } from '../Auth/store/AuthSelect'
import { changePasswordThunk } from '../Auth/store/AuthSlice'
import './Account.scss'

const cnAccount = cn('Account')

export const Account: FC = () => {
  const { t } = useTranslation('translation')
  const navigate = useNavigate()
  const alert = useAlert()
  const dispatch = useAppDispatch()

  const user = useAppSelector(userSelector)

  const [token, setToken] = useState<string>('')
  const [isVisible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    if (user) {
      const str = user.token.slice(0, 4) + '*************'

      setToken(str)
    }
  }, [user])

  const logout = () => {
    navigate('/')
    localStorage.removeItem('token')
  }

  const schema = yup
    .object({
      currentPassword: yup.string().trim().required().label('Current Password'),
      newPassword: yup.string().trim().required().label('New Password'),
    })
    .required()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<ChangePasswordModel>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const onSubmit = async (data: ChangePasswordModel) => {
    try {
      await dispatch(changePasswordThunk(data)).unwrap()

      alert.success('The user has been successfully updated')
    } catch (error) {
      console.log('onSubmit', error)
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('account-title')}</title>
        <meta name='description' content={t('account-description')} />
      </Helmet>
      <Card className={cnAccount()}>
        <div className={cnAccount('top')}>
          <Title>{t('account-h1')}</Title>
          <Button
            icon={<Icon name='logout' />}
            iconPosition='left'
            color='gray'
            onClick={logout}
          >
            {t('account-logout')}
          </Button>
        </div>

        <Title size='sm' className={cnAccount('subtitle')}>
          {t('account-setting')}
        </Title>
        <form className={cnAccount('form')} onSubmit={handleSubmit(onSubmit)}>
          {user && (
            <Field
              name='email'
              value={user?.email}
              disabled
              label={t('Email')}
            />
          )}

          <Field
            {...register('currentPassword')}
            type='password'
            label={t('Current Password')}
            errors={
              errors && errors.currentPassword && errors.currentPassword.message
            }
          />

          <Field
            {...register('newPassword')}
            type='password'
            label={t('New Password')}
            errors={errors && errors.newPassword && errors.newPassword.message}
          />

          <Button
            type='submit'
            icon={<Icon name='edit-pencil' />}
            iconPosition='left'
            color='gray'
            isLoading={isSubmitting}
          >
            {t('account-edit-button')}
          </Button>
        </form>

        {user && (
          <>
            <Title size='sm' className={cnAccount('subtitle')}>
              {t('Api key')}
            </Title>
            <Field name='token' value={isVisible ? user.token : token}>
              <div className={cnAccount('actions')}>
                <CopyToClipboard
                  text={user.token}
                  onCopy={() => alert.success(t('Copied!'))}
                >
                  <button type='button' className={cnAccount('eye')}>
                    <Icon name='copy' stroke='#000' />
                  </button>
                </CopyToClipboard>
                <button
                  type='button'
                  onClick={() => setVisible(!isVisible)}
                  className={cnAccount('eye')}
                >
                  <Icon name={isVisible ? 'eye-slash' : 'eye'} />
                </button>
              </div>
            </Field>
          </>
        )}

        {/* <Title size='sm' className={cnAccount('subtitle')}>
          {t('account-theme')}
        </Title> */}
      </Card>
    </>
  )
}
