import { cn } from '@bem-react/classname'
import qs from 'qs'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import {
  adminSelector,
  merchantsSelector,
} from '@/core/services/Admin/store/AppSelector'
import { getMerchantsThunk } from '@/core/services/Admin/store/AppSlice'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Card } from '@/components/UI/Card'
import { Icon } from '@/components/UI/Icon'
import { Title } from '@/components/UI/Title'

import { getUsersThunk } from '../Auth/store/AuthSlice'
import { AddUser } from './components/AddUser'
import { MerchantSlider } from './components/MerchantSlider'
import './styles/AdminDashboard.scss'

const cnAdminDashboard = cn('AdminDashboard')

export const AdminDashboard: FC = () => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()

  const merchants = useAppSelector(merchantsSelector)
  const isAdmin = useAppSelector(adminSelector)

  const [isAddUser, setAddUser] = useState<boolean>(false)

  useEffect(() => {
    if (isAdmin) getMerchants()
    else getData()
  }, [])

  const getMerchants = async () => {
    const query = qs.stringify({
      page: 1,
      pageSize: 200,
      sort: 'id:DESC',
    })

    try {
      await dispatch(getMerchantsThunk(query)).unwrap()
    } catch (error) {
      console.log('getMerchantsThunk', error)
    }
  }

  const getData = async () => {
    const query = qs.stringify({
      populate: '*',
      filters: {
        isAdmin: {
          $eq: false,
        },
      },
    })

    try {
      await dispatch(getUsersThunk(query)).unwrap()
    } catch (error) {
      console.log('getBalancesThunk', error)
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('AdminDashboard-title')}</title>
        <meta name='description' content={t('AdminDashboard-description')} />
      </Helmet>
      <div className={cnAdminDashboard()}>
        <Card className={cnAdminDashboard('card', { padding: true })}>
          <Title size='xxl' className={cnAdminDashboard('title')}>
            {t('Turnover statistic')}
          </Title>
        </Card>

        <Card className={cnAdminDashboard('card')}>
          <div className={cnAdminDashboard('value')}>
            <span className={'icon-USD'}></span>0
          </div>
          <div className={cnAdminDashboard('key')}>{t('Service balance')}</div>
        </Card>

        <Card className={cnAdminDashboard('card')}>
          <div className={cnAdminDashboard('value')}>
            <span className={'icon-USD'}></span>0
          </div>
          <div className={cnAdminDashboard('key')}>{t('Service balance')}</div>
        </Card>

        <Card className={cnAdminDashboard('card')}>
          <div className={cnAdminDashboard('value')}>
            <span className={'icon-USD'}></span>0
          </div>
          <div className={cnAdminDashboard('key')}>{t('Service balance')}</div>
        </Card>

        <Card className={cnAdminDashboard('card')}>
          <div className={cnAdminDashboard('value')}>
            <span className={'icon-USD'}></span>0
          </div>
          <div className={cnAdminDashboard('key')}>{t('Service balance')}</div>
        </Card>

        <Card className={cnAdminDashboard('card')}>
          <div className={cnAdminDashboard('value')}>
            <span className={'icon-USD'}></span>0
          </div>
          <div className={cnAdminDashboard('key')}>{t('Service balance')}</div>
        </Card>

        <Card className={cnAdminDashboard('card', { padding: true })}>
          <div className={cnAdminDashboard('top')}>
            <Title
              size='md'
              className={cnAdminDashboard('title', { top: true })}
            >
              {t('Turnover merchants')}
            </Title>
            <button
              type='button'
              className={cnAdminDashboard('link', { svg: true })}
              onClick={() => setAddUser(true)}
            >
              <Icon name='plus' />
              Create new
            </button>
          </div>

          {merchants && <MerchantSlider merchants={merchants.results} />}
        </Card>

        <Card className={cnAdminDashboard('card', { padding: true })}>
          <Title size='md' className={cnAdminDashboard('title')}>
            {t('Settlements')}
          </Title>
        </Card>
      </div>

      {isAddUser && (
        <AddUser
          open={isAddUser}
          close={() => setAddUser(false)}
          onSuccess={() => getData()}
        />
      )}
    </>
  )
}
