import { IDefaultResponse } from '@/core/interfaces'

import { http } from '@/utils/http'

import { IBalance, IDailyHistory, IHistory } from './AppModel'

class AppService {
  async getBalances(): Promise<IBalance> {
    const res = await http.get(`/account`)

    return res.data
  }

  async getAccountHistory(
    query: string,
  ): Promise<IDefaultResponse<IHistory[]>> {
    const res = await http.get(`/account/history${query ? '?' + query : ''}`)

    return res.data
  }

  async getAccountDailyHistory(
    query: string,
  ): Promise<IDefaultResponse<IDailyHistory[]>> {
    const res = await http.get(
      `/account/history/daily${query ? '?' + query : ''}`,
    )

    return res.data
  }
}

export default new AppService()
