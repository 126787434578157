import { cn } from '@bem-react/classname'
import {
  Cell,
  ColumnDef,
  ColumnSort,
  Header,
  HeaderGroup,
  Row,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import queryString from 'query-string'
import { FC, Fragment, ReactNode, useEffect, useRef, useState } from 'react'
import { positionValues } from 'react-custom-scrollbars'
import { useLocation, useNavigate } from 'react-router-dom'
import { VirtualItem, useVirtual } from 'react-virtual'
import useResizeObserver from 'use-resize-observer'

import { IDefaultMetaResponse } from '@/core/interfaces'

import { CustomScrollbars } from '../CustomScrollbars'
import { Icon } from '../Icon'
import { Pagination } from '../Pagination'
import './DataGrid.scss'

export type ColumnVisibilityState = Record<string, boolean>

interface IDataGrid {
  meta?: IDefaultMetaResponse
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[] | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<any>[]
  autoHeightMax?: number
  size?: number
  onClickRow?: (row: unknown) => void
  onMouseEnter?: (row: unknown) => void
  onMouseLeave?: (row: unknown) => void
  onResizeWidth?: (n: number) => void
  generateHtml?: (row: unknown) => ReactNode
  statusKey?: string
  visibility?: ColumnVisibilityState
  paginate?: 'none' | 'infinite' | 'default'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderSubComponent?: (props: { row: Row<any> }) => React.ReactElement
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRowCanExpand?: (row: Row<any>) => boolean
}

const cnDataGrid = cn('DataGrid')

export const DataGrid: FC<IDataGrid> = ({
  data,
  columns,
  autoHeightMax = 800,
  size = 10,
  visibility,
  onClickRow,
  onMouseEnter,
  onMouseLeave,
  onResizeWidth,
  generateHtml,
  getRowCanExpand,
  renderSubComponent,
  paginate = 'default',
  meta,
}) => {
  const navigate = useNavigate()
  const { ref, width = 1 } = useResizeObserver<HTMLDivElement>()
  const { search, pathname } = useLocation()

  const [sortible, setSortable] = useState<ColumnSort | null>(null)
  const [isScroll, setScroll] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tableData, setTableData] = useState<any[]>([])
  const tableContainerRef = useRef<HTMLTableElement>(null)
  const [columnVisibility, setColumnVisibility] =
    useState<ColumnVisibilityState>({})

  useEffect(() => {
    if (visibility) setColumnVisibility(visibility)
  }, [visibility])

  useEffect(() => {
    if (width && onResizeWidth) onResizeWidth(width)
  }, [width])

  useEffect(() => {
    if (data) setTableData(data) //setTableData(data.content)
  }, [data])

  useEffect(() => {
    if (search) {
      const query = queryString.parse(search)

      if (query && query.sort) {
        const arr = (query.sort as string).split(',')

        setSortable({
          id: arr[0],
          desc: arr[1] === 'ASC',
        })
      }
    }
  }, [])

  useEffect(() => {
    if (sortible) {
      const query = queryString.parse(search)

      if (query && !sortible.desc) {
        const str = queryString.stringify({
          ...query,
          sort: `${sortible.id},${sortible.desc ? 'DESC' : 'ASC'}`,
        })
        navigate(`${pathname}?${str}`)
      } else if (query) {
        const str = queryString.stringify({
          ...query,
          sort: `${sortible.id},${sortible.desc ? 'DESC' : 'ASC'}`,
        })
        navigate(`${pathname}?${str}`)
      } else {
        const str = queryString.stringify({
          sort: `${sortible.id},${sortible.desc ? 'DESC' : 'ASC'}`,
        })
        navigate(`${pathname}?${str}`)
      }
    }
  }, [sortible])

  const onClick = (header: Header<unknown, unknown>) => {
    const query = queryString.parse(search)
    let sort: ColumnSort | null = null

    switch (true) {
      case sortible === null || sortible.id !== header.id:
        sort = {
          id: header.id,
          desc: true,
        }

        break
      case sortible && sortible.id === header.id && sortible.desc:
        sort = {
          id: header.id,
          desc: false,
        }
        break

      case sortible && sortible.id === header.id && !sortible.desc:
        sort = null
        break
    }

    setSortable(sort)

    if (query && sort) {
      const str = queryString.stringify({
        ...query,
        sort: `${sort.id},${sort.desc ? 'DESC' : 'ASC'}`,
      })
      navigate(`${pathname}?${str}`)
    } else {
      if (query.sort) {
        delete query.sort
        const str = queryString.stringify(query)

        navigate(`${pathname}?${str}`)
      }
    }
  }

  const onChangePage = (page: number) => {
    const query = queryString.parse(search)

    if (query.page) {
      const str = queryString.stringify({
        ...query,
        page,
      })
      navigate(`${pathname}?${str}`)
    } else {
      const str = queryString.stringify({ ...query, page })
      navigate(`${pathname}?${str}`)
    }
  }

  const handleScrollFrame = (values: positionValues) => {
    const { top } = values

    if (top > 0) {
      setScroll(true)
    } else setScroll(false)

    if (
      top === 1 &&
      data //&& data.totalElements > data.size
    ) {
      if (search) {
        onChange(search)
      } else {
        onChange()
      }
    }
  }

  const onChange = (search = '') => {
    const query = queryString.parse(search)

    if (query.size) {
      const str = queryString.stringify({
        ...query,
        size: +query.size + size,
      })
      navigate(`${pathname}?${str}`)
    } else {
      const str = queryString.stringify({ ...query, size: size + size })
      navigate(`${pathname}?${str}`)
    }
  }

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand,
    debugTable: true,
  })

  const { rows } = table.getRowModel()

  //Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  })
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0

  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0

  return (
    <div ref={ref} className={cnDataGrid()}>
      <CustomScrollbars
        autoHeight
        autoHeightMax={width > 768 ? autoHeightMax : 1000}
        onScrollFrame={v => paginate === 'infinite' && handleScrollFrame(v)}
      >
        <table className={cnDataGrid('table')} ref={tableContainerRef}>
          <thead>
            {table
              .getHeaderGroups()
              .map((headerGroup: HeaderGroup<unknown>) => (
                <tr className={cnDataGrid('row')} key={headerGroup.id}>
                  {headerGroup.headers.map(
                    (header: Header<unknown, unknown>) => {
                      return (
                        <th
                          className={cnDataGrid('th', { scroll: isScroll })}
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{ minWidth: header.getSize() }}
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              {header.column.getCanSort() ? (
                                <button
                                  type='button'
                                  className={cnDataGrid('btn')}
                                  onClick={() => {
                                    onClick(header)
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}

                                  {header.column.getCanSort() && (
                                    <Icon
                                      name='sort'
                                      className={cnDataGrid('icon', {
                                        up:
                                          sortible?.desc &&
                                          header.id === sortible.id,
                                        down:
                                          !sortible?.desc &&
                                          header.id === sortible?.id,
                                        none: sortible === null,
                                      })}
                                    />
                                  )}
                                </button>
                              ) : (
                                <>
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </th>
                      )
                    },
                  )}
                </tr>
              ))}
          </thead>

          <tbody className={cnDataGrid('tbody')}>
            {paddingTop > 0 && (
              <tr className={cnDataGrid('row')}>
                <td
                  className={cnDataGrid('td')}
                  style={{ height: `${paddingTop}px` }}
                />
              </tr>
            )}

            {virtualRows.map((virtualRow: VirtualItem) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const row = rows[virtualRow.index] as Row<any>

              return (
                <Fragment key={row.id}>
                  <tr
                    className={cnDataGrid('row')}
                    onClick={() =>
                      onClickRow && row && onClickRow(row.original)
                    }
                    onMouseEnter={() =>
                      onMouseEnter && row && onMouseEnter(row.original)
                    }
                    onMouseLeave={() =>
                      onMouseLeave && row && onMouseLeave(row.original)
                    }
                  >
                    {row
                      .getVisibleCells()
                      .map((cell: Cell<unknown, unknown>) => {
                        return (
                          <td
                            key={cell.id}
                            className={cnDataGrid('td')}
                            style={{
                              // borderLeft:
                              //   statusKey && i === 0
                              //     ? `3px solid ${setColor(
                              //         row.original[statusKey],
                              //       )}`
                              //     : '',
                              maxWidth: cell.column.columnDef.maxSize,
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        )
                      })}

                    {generateHtml && generateHtml(row.original)}
                  </tr>
                  {row.getIsExpanded() && renderSubComponent && (
                    <tr className={cnDataGrid('row')}>
                      {/* 2nd row is a custom 1 cell row */}
                      <td
                        colSpan={row.getVisibleCells().length}
                        className={cnDataGrid('td', { sub: true })}
                      >
                        {renderSubComponent({ row })}
                      </td>
                    </tr>
                  )}
                </Fragment>
              )
            })}

            {paddingBottom > 0 && (
              <tr className={cnDataGrid('row')}>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </CustomScrollbars>

      {paginate === 'default' && meta && (
        <Pagination
          currentPage={meta.currentPage}
          maxPages={meta.totalPages}
          totalItems={meta.totalItems}
          paginate={onChangePage}
        />
      )}
    </div>
  )
}
