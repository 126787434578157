import { cn } from '@bem-react/classname'
import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import qs from 'qs'
import queryString from 'query-string'
import { FC, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Card } from '@/components/UI/Card'
import { DataGrid } from '@/components/UI/DataGrid'
import { Loading } from '@/components/UI/Loading'
import { Title } from '@/components/UI/Title'

import './Clients.scss'
import { ICustomerResponse } from './models/CardModel'
import { customersSelector } from './store/ClientsSelector'
import { getClientsThunk } from './store/ClientsSlice'

const cnClients = cn('Clients')

export const Clients: FC = () => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const { search } = useLocation()

  const customers = useAppSelector(customersSelector)

  const [isLoading, setLoading] = useState<boolean>(true)

  const columns = useMemo<ColumnDef<ICustomerResponse>[]>(
    () => [
      {
        id: 'id_doc',
        header: 'Doc ID',
        accessorKey: 'id_doc',
        enableSorting: false,
        // maxSize: 150,
      },

      {
        id: 'first_name',
        header: 'First Name',
        accessorKey: 'first_name',
        enableSorting: false,
        // maxSize: 150,
      },

      {
        id: 'last_name',
        header: 'Last Name',
        accessorKey: 'last_name',
        enableSorting: false,
        // maxSize: 150,
      },

      {
        id: 'phone',
        header: 'Phone',
        accessorKey: 'phone',
        enableSorting: false,
        // maxSize: 150,
      },

      {
        id: 'dob',
        header: 'Birthday',
        accessorKey: 'dob',
        cell: ({ row }) => dayjs(row.original.dob).format('DD.MM.YYYY'),
        enableSorting: false,
        // maxSize: 150,
      },

      {
        id: 'created_at',
        header: 'Created At',
        accessorKey: 'created_at',
        cell: ({ row }) =>
          dayjs(row.original.created_at).format('DD.MM.YYYY HH:mm'),
        enableSorting: false,
        // maxSize: 150,
      },
    ],
    [],
  )

  useEffect(() => {
    if (search) {
      const query = queryString.parse(search)

      if (query && query.page) {
        getClients(+query.page)
      }
    } else {
      getClients(1)
    }
  }, [search])

  const getClients = async (page = 1) => {
    const query = qs.stringify({
      page,
      limit: 10,
    })
    try {
      await dispatch(getClientsThunk(query)).unwrap()
      setLoading(false)
    } catch (error) {
      console.log('getClients', error)
      setLoading(false)
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('Clients-title')}</title>
        <meta name='description' content={t('Clients-description')} />
      </Helmet>
      <Card className={cnClients()}>
        <div className={cnClients('top')}>
          <Title>{t('Clients')}</Title>
        </div>

        {isLoading ? (
          <Loading type='logo' minHeight='300px' />
        ) : (
          <DataGrid
            data={customers?.items || []}
            columns={columns}
            meta={customers?.meta}
          />
        )}
      </Card>
    </>
  )
}
