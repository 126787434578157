import { IDefaultResponse } from '@/core/interfaces'

import { http } from '@/utils/http'

import { ICardResponse } from './models/CardModel'

class CardsService {
  async getCards(query: string): Promise<IDefaultResponse<ICardResponse[]>> {
    const res = await http.get(`/card${query ? '?' + query : ''}`)

    return res.data
  }
}

export default new CardsService()
