import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import { TypeSize } from '@/core/types'

import './Title.scss'

const cnTitle = cn('Title')

interface ITitle
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
  size?: TypeSize
}

export const Title: FC<ITitle> = ({ children, className, size = 'xxl' }) => {
  return (
    <div
      className={cnTitle(
        {
          sm: size === 'sm',
          md: size === 'md',
          xl: size === 'xl',
          xxl: size === 'xxl',
        },
        [className],
      )}
    >
      {children}
    </div>
  )
}
