import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { Outlet } from 'react-router-dom'

import { Card } from '@/components/UI/Card'

import './Auth.scss'

const cnAuth = cn('Auth')

interface IAuth
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const Auth: FC<IAuth> = ({ className }) => {
  return (
    <div className={cnAuth(null, [className])}>
      <Card className={cnAuth('card')}>
        <Outlet />
      </Card>
    </div>
  )
}
