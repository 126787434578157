import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import { adminSelector } from '@/core/services/Admin/store/AppSelector'
import { useAppSelector } from '@/core/store/hooks'

import { Card } from '../../UI/Card'
import { Logo } from '../Logo'
import { Menu } from '../Menu'
import './Sidebar.scss'

const cnSidebar = cn('Sidebar')

interface ISidebar
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const Sidebar: FC<ISidebar> = ({ className }) => {
  const isAdmin = useAppSelector(adminSelector)

  const menu = [
    {
      title: 'menu-dashboard',
      icon: 'menu-dashboard',
      href: `/${isAdmin ? 'a' : 'c'}/dashboard`,
    },
    // {
    //   title: 'menu-merchants',
    //   icon: 'menu-merchants',
    //   href: '/c/merchants',
    // },
    // {
    //   title: 'menu-transactions',
    //   icon: 'menu-transactions',
    //   href: '/c/transactions',
    // },
    {
      title: 'menu-invoices',
      icon: 'menu-invoices',
      href: `/${isAdmin ? 'a' : 'c'}/invoices`,
    },

    {
      title: 'menu-cards',
      icon: 'menu-cards',
      href: `/${isAdmin ? 'a' : 'c'}/cards`,
    },

    {
      title: 'menu-clients',
      icon: 'menu-clients',
      href: `/${isAdmin ? 'a' : 'c'}/clients`,
    },
  ]

  const account = [
    {
      title: 'menu-account',
      icon: 'menu-account',
      href: `/${isAdmin ? 'a' : 'c'}/account`,
    },
  ]

  return (
    <Card className={cnSidebar(null, [className])}>
      <Logo />
      <Menu data={menu} className={cnSidebar('menu')} />
      <Menu data={account} className={cnSidebar('account')} />
    </Card>
  )
}
