import { cn } from '@bem-react/classname'
import { FC, ReactNode } from 'react'

import { adminSelector } from '@/core/services/Admin/store/AppSelector'
import { useAppSelector } from '@/core/store/hooks'

import useWindowSize from '@/utils/useWindowSize'

import { Logo } from '../Logo'
import { Menu } from '../Menu'
import { Sidebar } from '../Sidebar'
import './Private.scss'

interface IPrivate {
  children: ReactNode
}

const cnPrivate = cn('Private')

export const Private: FC<IPrivate> = ({ children }: IPrivate) => {
  const { width } = useWindowSize()
  const isAdmin = useAppSelector(adminSelector)

  const menu = [
    {
      title: 'menu-dashboard',
      icon: 'menu-dashboard',
      href: `/${isAdmin ? 'a' : 'c'}/dashboard`,
    },

    {
      title: 'menu-invoices',
      icon: 'menu-invoices',
      href: `/${isAdmin ? 'a' : 'c'}invoices`,
    },

    {
      title: 'menu-cards',
      icon: 'menu-cards',
      href: `/${isAdmin ? 'a' : 'c'}/cards`,
    },

    {
      title: 'menu-clients',
      icon: 'menu-clients',
      href: `/${isAdmin ? 'a' : 'c'}/clients`,
    },
    {
      title: 'menu-account',
      icon: 'menu-account',
      href: `/${isAdmin ? 'a' : 'c'}/account`,
    },
  ]

  return (
    <div className={cnPrivate()}>
      {width > 768 ? (
        <Sidebar className={cnPrivate('sidebar')} />
      ) : (
        <div className={cnPrivate('top')}>
          <Logo to='/c/dashboard' />
        </div>
      )}
      <div className={cnPrivate('content')}>{children}</div>

      {width <= 768 && (
        <Menu data={menu} isMobile className={cnPrivate('menu')} />
      )}
    </div>
  )
}
