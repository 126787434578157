import { cn } from '@bem-react/classname'
import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import qs from 'qs'
import queryString from 'query-string'
import { FC, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { IInvoiceResponse } from '@/core/services/Admin/AdminModel'
import { invoicesAdminSelector } from '@/core/services/Admin/store/AppSelector'
import {
  getAdminInvoicesThunk,
  getMerchantsThunk,
} from '@/core/services/Admin/store/AppSlice'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Button } from '@/components/UI/Button'
import { Card } from '@/components/UI/Card'
import { DataGrid } from '@/components/UI/DataGrid'
import { Icon } from '@/components/UI/Icon'
import { NoData } from '@/components/UI/NoData'
import { Status, StatusType } from '@/components/UI/Status'
import { Title } from '@/components/UI/Title'

import './AdminInvoices.scss'
import { CreateInvoice } from './components/CreateInvoice'
import { AdminViewInvoice } from './components/ViewInvoce/AdminViewInvoice'

const cnAdminInvoices = cn('AdminInvoices')

export const AdminInvoices: FC = () => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const { search } = useLocation()

  const invoices = useAppSelector(invoicesAdminSelector)

  const [isCreateInvoice, setCreateInvoice] = useState<boolean>(false)
  const [isViewInvoice, setViewInvoice] = useState<boolean>(false)
  const [invoice, setInvoice] = useState<IInvoiceResponse | null>(null)

  useEffect(() => {
    getMerchants()
  }, [])

  useEffect(() => {
    if (search) {
      const query = queryString.parse(search)

      if (query && query.page) {
        getInvoices(+query.page)
      }
    } else {
      getInvoices(1)
    }
  }, [search])

  const getMerchants = async () => {
    const query = qs.stringify({
      page: 1,
      pageSize: 200,
      sort: 'id:DESC',
    })

    try {
      await dispatch(getMerchantsThunk(query)).unwrap()
    } catch (error) {
      console.log('getMerchantsThunk', error)
    }
  }

  const getInvoices = async (page = 1) => {
    const query = qs.stringify({
      page,
      pageSize: 10,
      sort: ['id:desc'],
    })

    try {
      await dispatch(getAdminInvoicesThunk(query)).unwrap()
    } catch (error) {
      console.log('getAdminInvoicesThunk', error)
    }
  }

  const onSuccess = async () => {
    setCreateInvoice(false)
    await getInvoices()
  }

  const columns = useMemo<ColumnDef<IInvoiceResponse>[]>(
    () => [
      {
        id: 'ID',
        header: 'id',
        accessorKey: 'id',
        enableSorting: false,
        maxSize: 150,
      },

      {
        id: 'company',
        header: 'Company',
        accessorKey: 'users_permissions_user.username',
        enableSorting: false,
        maxSize: 150,
      },

      {
        id: 'invoiceDate',
        header: 'Create date',
        cell: ({ row }) => dayjs(row.original.invoiceDate).format('DD.MM.YYYY'),
        enableSorting: false,
        maxSize: 150,
      },
      {
        id: 'dueDate',
        header: 'Due Date',
        cell: ({ row }) => dayjs(row.original.dueDate).format('DD.MM.YYYY'),
        enableSorting: false,
        maxSize: 150,
      },

      {
        id: 'payment_status',
        header: 'Payment Status',

        cell: ({ row }) => (
          <Status type={row.original.payment_status.name as StatusType}>
            {row.original.payment_status.name}
          </Status>
        ),
        enableSorting: false,
        maxSize: 150,
      },

      {
        id: 'actions',
        header: () => null,
        cell: ({ row }) => (
          <div className={cnAdminInvoices('actions')}>
            <Button
              color='gray'
              onClick={() => {
                setInvoice(row.original)
                setViewInvoice(true)
              }}
            >
              <Icon name='eye' width={20} height={20} stroke='#2B2B2B' />
            </Button>

            {row.original.payment_status.name === 'UNPAID' && (
              <Button
                color='gray'
                onClick={() => {
                  setInvoice(row.original)
                  setCreateInvoice(true)
                }}
              >
                <Icon name='edit' width={20} height={20} fill='#2B2B2B' />
              </Button>
            )}
          </div>
        ),
        enableSorting: false,
        maxSize: 120,
      },
    ],
    [],
  )

  return (
    <>
      <Helmet>
        <title>{t('invoices-title')}</title>
        <meta name='description' content={t('invoices-description')} />
      </Helmet>
      <div className={cnAdminInvoices()}>
        <Card className={cnAdminInvoices('card')}>
          <div className={cnAdminInvoices('header')}>
            <Title size='md' className={cnAdminInvoices('title')}>
              {t('Invoices')}
            </Title>
            <div className={cnAdminInvoices('actions')}>
              <Button
                iconPosition='left'
                icon={<Icon name='plus' fill='white' />}
                onClick={() => setCreateInvoice(true)}
              >
                {t('Create invoice')}
              </Button>
              {/* <Button
                iconPosition='left'
                icon={<Icon name='filter' />}
                color='gray'
              >
                {t('Filter')}
              </Button> */}
            </div>
          </div>
          {invoices ? (
            <DataGrid
              data={invoices.results}
              columns={columns}
              meta={{
                currentPage: invoices.pagination.page,
                totalItems: invoices.pagination.total,
                totalPages: invoices.pagination.pageCount,
              }}
            />
          ) : (
            <NoData
              icon='invoice'
              text={t('There have not been any invoices yet')}
            />
          )}
        </Card>
      </div>
      <CreateInvoice
        close={() => setCreateInvoice(false)}
        onSuccess={onSuccess}
        open={isCreateInvoice}
        invoice={invoice}
      />

      {invoice && (
        <AdminViewInvoice
          invoice={invoice}
          close={() => {
            setInvoice(null)
            setViewInvoice(false)
          }}
          onSuccess={() => getInvoices()}
          open={isViewInvoice}
        />
      )}
    </>
  )
}
