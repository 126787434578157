import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { PrivateRoute } from '@/core/PrivateRoute'

import { getItem } from '@/utils/persistanceStorage'

import { Auth } from '@/components/Layout/Auth'

import { Account } from './Account'
import { Login } from './Auth'
import { Cards } from './Cards'
import { AdminClients, Clients } from './Clients'
import { AdminDashboard, Dashboard } from './Dashboard'
import { History } from './History'
import { Invoices } from './Invoices'
import { AdminInvoices } from './Invoices/AdminInvoices'
import { PageNotFound } from './PageNotFound'

export const AppCunex = () => {
  const { i18n } = useTranslation()

  useEffect(() => {
    const lang = getItem('lang', false)

    if (lang) {
      i18n.changeLanguage(lang)
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Auth />}>
          <Route index element={<Login />} />
          <Route path='admin' element={<Login />} />
          <Route path='*' element={<PageNotFound />} />
        </Route>

        <Route path='c' element={<PrivateRoute />}>
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='account' element={<Account />} />
          <Route path='invoices' element={<Invoices />} />
          <Route path='history/:slug' element={<History />} />
          <Route path='cards' element={<Cards />} />
          <Route path='clients' element={<Clients />} />
          <Route path='*' element={<PageNotFound />} />
        </Route>

        <Route path='a' element={<PrivateRoute />}>
          <Route path='dashboard' element={<AdminDashboard />} />
          <Route path='invoices' element={<AdminInvoices />} />
          <Route path='account' element={<Account />} />
          <Route path='clients' element={<AdminClients />} />
          <Route path='*' element={<PageNotFound />} />
        </Route>
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  )
}
