import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IDefaultResponse } from '@/core/interfaces'

import ClientsService from '../ClientsService'
import { ICustomerResponse } from '../models/CardModel'

interface ClientsState {
  customers: IDefaultResponse<ICustomerResponse[]> | null
}

const initialState: ClientsState = {
  customers: null,
}

export const getClientsThunk = createAsyncThunk(
  'clients/getClientsThunk',
  async (query: string, { rejectWithValue }) => {
    try {
      return await ClientsService.getClients(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder

      .addCase(
        getClientsThunk.fulfilled,
        (
          state,
          action: PayloadAction<IDefaultResponse<ICustomerResponse[]>>,
        ) => {
          state.customers = action.payload
        },
      )
      .addCase(getClientsThunk.rejected, state => {
        state.customers = null
      })
  },
})

export const { actions, reducer } = clientsSlice
