/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import InvoiceService from '../InvoiceService'
import { IInvoices, InvoiceModel } from '../models/InvoiceModel'

interface InvoiceState {
  invoices: IInvoices | null
}

const initialState: InvoiceState = {
  invoices: null,
}

export const createInvoiceThunk = createAsyncThunk(
  'invoice/createInvoiceThunk',
  async (data: InvoiceModel, { rejectWithValue }) => {
    try {
      return await InvoiceService.createInvoice(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getInvoicesThunk = createAsyncThunk(
  'invoice/getInvoicesThunk',
  async (query: string, { rejectWithValue }) => {
    try {
      return await InvoiceService.getInvoices(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const updateInvoiceThunk = createAsyncThunk(
  'invoice/updateInvoiceThunk',
  async (invoice: any, { rejectWithValue }) => {
    try {
      return await InvoiceService.updateInvoice(invoice)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder

      .addCase(getInvoicesThunk.fulfilled, (state, action) => {
        state.invoices = action.payload
      })
      .addCase(getInvoicesThunk.rejected, state => {
        state.invoices = null
      })
  },
})

export const { actions, reducer } = invoiceSlice
