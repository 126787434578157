import { cn } from '@bem-react/classname'
import dayjs from 'dayjs'
import { AnimatePresence, motion } from 'framer-motion'
import { FC, useState } from 'react'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'

import { IInvoiceResponse } from '@/core/services/Admin/AdminModel'
import { updateAdminInvoiceThunk } from '@/core/services/Admin/store/AppSlice'
import { useAppDispatch } from '@/core/store/hooks'

import { Button } from '@/components/UI/Button'
import { Icon } from '@/components/UI/Icon'

import './ViewInvoice.scss'

const cnViewInvoice = cn('ViewInvoice')

interface IViewInvoice {
  close: () => void
  onSuccess: () => void
  invoice: IInvoiceResponse
  open?: boolean
}

export const AdminViewInvoice: FC<IViewInvoice> = ({
  close,
  onSuccess,
  invoice,
  open,
}) => {
  const { t } = useTranslation('translation')
  const alert = useAlert()
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState<boolean>(false)

  const paid = async () => {
    setLoading(true)
    try {
      await dispatch(
        updateAdminInvoiceThunk({
          ...invoice,
          payment_status: { ...invoice.payment_status, id: 1 },
        }),
      ).unwrap()

      alert.success('Invoice updated')
      onSuccess()
      close()

      setLoading(false)
    } catch (error) {
      setLoading(false)

      console.log('paid', error)
    }
  }

  return (
    <AnimatePresence>
      {open && (
        <div className={cnViewInvoice()}>
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={cnViewInvoice('overlay')}
            onClick={() => close()}
          ></motion.div>
          <motion.div
            initial={{ x: '100%' }}
            exit={{ x: '100%' }}
            animate={{ x: 0 }}
            className={cnViewInvoice('wrap')}
          >
            <div className={cnViewInvoice('top')}>
              <div className={cnViewInvoice('title')}>Invoice Details</div>
              <button type='button' onClick={close}>
                <Icon name='close' />
              </button>
            </div>

            <div className={cnViewInvoice('details')}>
              <div className={cnViewInvoice('item')}>
                <div className={cnViewInvoice('key')}>ID</div>
                <div className={cnViewInvoice('value')}>{invoice.id}</div>
              </div>

              <div className={cnViewInvoice('item')}>
                <div className={cnViewInvoice('key')}>Created At</div>
                <div className={cnViewInvoice('value')}>
                  {dayjs(invoice.invoiceDate).format('DD.MM.YYYY')}
                </div>
              </div>

              <div className={cnViewInvoice('item')}>
                <div className={cnViewInvoice('key')}>Expired At</div>
                <div className={cnViewInvoice('value')}>
                  {dayjs(invoice.dueDate).format('DD.MM.YYYY')}
                </div>
              </div>

              {invoice.replenishmentLiquidityBalance && (
                <div className={cnViewInvoice('item')}>
                  <div className={cnViewInvoice('key')}>Liquidity Balance</div>
                  <div className={cnViewInvoice('value')}>
                    <span className={'icon-USD'}></span>
                    {invoice.replenishmentLiquidityBalance | 0}
                  </div>
                </div>
              )}

              {invoice.replenishmentServiceBalance && (
                <div className={cnViewInvoice('item')}>
                  <div className={cnViewInvoice('key')}>Service Balance</div>
                  <div className={cnViewInvoice('value')}>
                    <span className={'icon-USD'}></span>
                    {invoice.replenishmentServiceBalance | 0}
                  </div>
                </div>
              )}

              <div className={cnViewInvoice('item')}>
                <div className={cnViewInvoice('key')}>Monthly Service Fee</div>
                <div className={cnViewInvoice('value')}>
                  <span className={'icon-USD'}></span>
                  {invoice &&
                    invoice.monthlyServiceFee &&
                    invoice.monthlyServiceFee | 0}
                </div>
              </div>

              <div className={cnViewInvoice('item')}>
                <div className={cnViewInvoice('key')}>Status</div>
                <div className={cnViewInvoice('value')}>
                  {invoice.payment_status.title}
                </div>
              </div>

              <div className={cnViewInvoice('item')}>
                <div className={cnViewInvoice('key')}>Company</div>
                <div className={cnViewInvoice('value')}>
                  {invoice.users_permissions_user.username}
                </div>
              </div>
            </div>

            {invoice.payment_status.name === 'UNPAID' && (
              <Button
                className={cnViewInvoice('paid')}
                onClick={paid}
                isLoading={loading}
              >
                {t('Paid')}
              </Button>
            )}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}
